import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class UniversalItem extends React.Component {
    constructor(props) {
        super(props);
    }


    addToCart(item) {


        this.props.shopCore.addToCart({ _id: item._id, quantity: parseInt(item.MinOrder) }, (data) => {
            if (data.successful) {
                this.props.showInfoMessage('Proizvod je uspešno dodat u korpu!');
                this.props.shopCore.cartInfo('cartInfo');
            } else {
                this.props.showInfoMessage('Proizvod trenutno nije na stanju', true);

            }

        });



    }


    render() {
 

        return (

            <div className="boxWrapper">
                  <Link to={`/product/${this.props._id}/${this.props.Alias}`}><img src={this.props.Images && this.props.Images[0]} className="img-fluid" /></Link>
                  {this.props.mpcDiscount || this.props.vpcDiscount ?
                                                <div className="action">
                                                    <p>-{this.props.mpcDiscount ? this.props.mpcDiscount + '%' : this.props.vpcDiscount + '%'}</p>
                                                </div>

                                                : null}
                    <div className="box">
                        <h6>{this.props.Name}</h6>
                        <p className = "subtitle">{this.props.Manufacturer}, {this.props.attributes && this.props.attributes['zemlja-porekla']}</p>
                        <p>{this.props.shopCore.formatPrice(this.props.price, 2)} RSD</p>
                        <button onClick={() => this.addToCart(this.props)} className = "button1">U KORPU</button>
                    </div>
            </div>

        );
    }
}


export default UniversalItem;