import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import Navigacija from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';
import LoginForm from '../../components/forms/loginForm';
import RegisterForm from '../../components/forms/registerForm';

import {
    Container,
    Col,
    Row,
    Button,
    FormGroup,
    Input,
    Label
} from 'reactstrap';


export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.register = this.register.bind(this);
        this.login = this.login.bind(this);

        this.state = {
            menu: {}
        }
    }

    componentWillMount() {
        window.scroll(0, 0);
    }

    register(data) {
        console.log(data);
        data.ShippingAddress = [];
        data.User.Name = data.BillingAddress.FirstName + " " + data.BillingAddress.LastName;
        data.BillingAddress.EMail = data.User.EMail;

        this.props.shopCore.userRegister(data, (data) => {
            if (data.successful) {
                this.setState({
                    successful: true
                })
            } else {
                if (data.error == 'user-exists') {
                    this.setState({
                        registerError: 'Korisnik već postoji!'
                    });
                } else if (data.error == 'passwords-not-match') {
                    this.setState({
                        registerError: 'Lozinke se ne podudaraju'
                    })
                }
            }


        })


    }

    login(data) {
        this.props.shopCore.userLogin(data, (data) => {
            console.log(data);
            if (data.successful) {
                this.props.setUserData(data.user);
                //this.props.socketIOClient.emit('cartInfo', {});

            } else {
                if (data.error == 'email-not-verified') {
                    this.setState({
                        loginError: 'Potvrdite Vašu e-mail adresu da bi ste se prijavili!'
                    });
                } else if (data.error == 'user-not-exists') {
                    this.setState({
                        loginError: 'Korisnik nije pronadjen'
                    });
                } else if (data.error == 'wrong-credentials') {
                    this.setState({
                        loginError: 'Pogrešni podaci'
                    })
                }

            }
        });
    }

    componentDidMount(){
        if (this.props[0].location.pathname == '/register'){
            this.setState({
                menu: {item1: true}
            })
        }
    }

    render() {
        return (
            <>
                {
                    this.props.uData ? <Redirect to='/profile' ></Redirect> : null
                }

                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> / <span>Prijava</span></h3>
                    </div>
                </Container>
                <Container>
                    <Col xl="6" lg="6" md="6" xs="12" className="mx-auto">
                        <div className="login">
                            <div className="switchContent d-flex">
                                <h4 className={this.state.menu.item1 ? "" : "activ"} onClick={() => {
                                    let menu = this.state.menu;
                                    menu.item1 = !menu.item1;
                                    this.setState({ menu });

                                }}>Prijava</h4>
                                <h4 className={this.state.menu.item1 ? "activ" : ""} onClick={() => {
                                    let menu = this.state.menu;
                                    menu.item1 = !menu.item1;
                                    this.setState({ menu });
                                }}>Registracija</h4>
                            </div>

                            <div className="loginBox">
                                {!this.state.menu.item1 ?
                                    <div className="content">
                                        <h6>Prijavite se na vaš nalog.</h6>
                                        <LoginForm onSubmit={this.login}></LoginForm>
                                        {
                                            this.state.loginError ?
                                                <div className="alert alert-danger" role="alert">
                                                    {this.state.loginError}
                                                </div>

                                                : null
                                        }

                                    </div>
                                    : null}




                                {this.state.menu.item1 ?
                                    <div className="content reg">
                                        <h6>Nemate nalog? Registrujte se.</h6>

                                        {!this.state.successful ?

                                            <RegisterForm onSubmit={this.register}></RegisterForm>
                                            :
                                            <div>
                                                <h3>Vaš nalog je uspešno kreiran</h3>
                                                <p>Potvrdite Vaš email</p>
                                            </div>
                                        }

                                        {
                                            this.state.registerError ?
                                                <div className="alert alert-danger" role="alert">
                                                    {this.state.registerError}
                                                </div>

                                                : null
                                        }

                                    </div>
                                    : null}
                            </div>
                        </div>
                    </Col>

                </Container>


                <div className="whiteFooter">
                    <Footer {...this.props}></Footer>
                </div>
            </>
        );
    }
}