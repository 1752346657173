import React from 'react'
import { Link } from 'react-router-dom';

import Navigacija from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';

import CartImg from '../../images/shopingCart/cart.png';
import {
    Col,
    Container,
    Row,
    Button,
    Input
} from 'reactstrap';


export default class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.updateQuantity = this.updateQuantity.bind(this);
        this.updateCurrentOrder = this.updateCurrentOrder.bind(this);
        this.removeCartItem = this.removeCartItem.bind(this);

        this.state = {
            step: 0,
            checked: true,
            clicks: 0

        };

    }

    IncrementItem = (idx) => {
        this.updateQuantity(idx, this.state.order.products[idx].cartQuantity + 1)
        //this.setState({ clicks: this.state.clicks + 1 });
    }

    DecreaseItem = (idx) => {
        if (this.state.order.products[idx].cartQuantity - 1 < 0) { return; }
        this.updateQuantity(idx, this.state.order.products[idx].cartQuantity - 1)
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.shopCore.fetchCurrentOrder((data) => {
            if (data.message) {
                this.props.showInfoMessage(data.message, true);
            }
            console.log(data);
            this.setState({
                order: data.order,
                deliveryMethods: data.deliveryMethods
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.uData && !prevProps.uData) {

            this.props.shopCore.fetchCurrentOrder((data) => {
                if (data.message) {
                    this.props.showInfoMessage(data.message, true);
                }

                this.setState({
                    order: data.order,
                    deliveryMethods: data.deliveryMethods
                });
            });

        }
    }

    updateQuantity(idx, val) {
        let order = this.state.order;

        order.products[idx].cartQuantity = val;

        this.setState({
            order: order,
            cartChanged: true
        })

    }


    updateCurrentOrder() {
        let obj = {
            products: this.state.order.products
        }

        this.props.shopCore.updateCurrentOrder({ products: this.state.order.products }, (data) => {
            if (data.message) {
                this.props.showInfoMessage(data.message, true);
            }

            this.props.shopCore.fetchCurrentOrder((data) => {
                if (data.message) {
                    this.props.showInfoMessage(data.message, true);
                }

                this.setState({
                    order: data.order,
                    deliveryMethods: data.deliveryMethods,
                    cartChanged: null
                });
            });

        });

    }

    removeCartItem(id) {
        this.props.shopCore.removeCartItem({ productId: id }, () => {

            this.props.shopCore.fetchCurrentOrder((data) => {
                if (data.message) {
                    this.props.showInfoMessage(data.message, true);
                }

                this.setState({
                    order: data.order,
                    deliveryMethods: data.deliveryMethods
                });
            });

            this.props.shopCore.cartInfo();

        });

    }


    render() {
        let cartItems = this.state.order ? this.state.order.products : [];

        return (
            <div>
                <Navigacija {...this.props} />

                <Container>
                    <Row>
                        <Col xl="4" lg="4" md="4" xs="12">
                            <div className="cartBox">
                                <h6>DOSTAVA</h6>
                                <ul>
                                    {this.state.deliveryMethods && this.state.deliveryMethods.map((item, idx) => {
                                        return (
                                            <li className="delivery"><Input checked={this.state.order.DeliveryMethod._id == item._id ? true : false} type="radio" name="delivery" onChange={(e) => {
                                                if (e.target.value) {
                                                    this.props.shopCore.updateCurrentOrder({ DeliveryMethod: item }, (data) => {
                                                        this.props.shopCore.fetchCurrentOrder((data) => {
                                                            if (data.message) {
                                                                this.props.showInfoMessage(data.message, true);
                                                            }

                                                            this.setState({
                                                                order: data.order,
                                                                deliveryMethods: data.deliveryMethods,
                                                                cartChanged: null
                                                            });
                                                        });

                                                    });
                                                }
                                            }} /><p>{item.name}</p></li>
                                        )
                                    })
                                    }
                                </ul>
                                <h5>NAČIN PLAĆANJA</h5>
                                <ul>
                                    <li className="prvi"><Input onChange={(e) => {
                                        if (e.target.value) {
                                            this.props.shopCore.updateCurrentOrder({ BillingMethod: 'placanje-prilikom-pouzeca' }, (data) => {
                                                this.props.shopCore.fetchCurrentOrder((data) => {
                                                    if (data.message) {
                                                        this.props.showInfoMessage(data.message, true);
                                                    }

                                                    this.setState({
                                                        order: data.order,
                                                        deliveryMethods: data.deliveryMethods,
                                                        cartChanged: null
                                                    });
                                                });

                                            });
                                        }
                                    }} checked={this.state.order && this.state.order.BillingMethod == 'placanje-prilikom-pouzeca' ? true : false} type="radio" name="pay" />Plaćanje pouzećem</li>
                                    <li><Input type="radio" name="pay" onChange={(e) => {
                                        if (e.target.value) {
                                            this.props.shopCore.updateCurrentOrder({ BillingMethod: 'uplata-na-tekuci-racun' }, (data) => {
                                                this.props.shopCore.fetchCurrentOrder((data) => {
                                                    if (data.message) {
                                                        this.props.showInfoMessage(data.message, true);
                                                    }

                                                    this.setState({
                                                        order: data.order,
                                                        deliveryMethods: data.deliveryMethods,
                                                        cartChanged: null
                                                    });
                                                });

                                            });
                                        }
                                    }} checked={this.state.order && this.state.order.BillingMethod == 'uplata-na-tekuci-racun' ? true : false} />Žiralno plaćanje</li>
                                    <li><Input type="radio" name="pay" onChange={(e) => {
                                        if (e.target.value) {
                                            this.props.shopCore.updateCurrentOrder({ BillingMethod: 'kreditna-kartica' }, (data) => {
                                                this.props.shopCore.fetchCurrentOrder((data) => {
                                                    if (data.message) {
                                                        this.props.showInfoMessage(data.message, true);
                                                    }

                                                    this.setState({
                                                        order: data.order,
                                                        deliveryMethods: data.deliveryMethods,
                                                        cartChanged: null
                                                    });
                                                });

                                            });
                                        }
                                    }} checked={this.state.order && this.state.order.BillingMethod == 'kreditna-kartica' ? true : false} />Platnom karticom</li>
                                </ul>
                            </div>
                        </Col>
                        <Col xl="8" lg="8" md="8" xs="12">
                            {/*start mobile version */}
                            <div className="wrapperMobleCart  d-md-none d-lg-none">

                                {
                                    cartItems.map((item, idx) => {
                                        return (

                                            <div key={idx} className="cartBoxMobile">
                                                <Row>
                                                    <Col xs="4" className="d-flex">
                                                        <span onClick={() => this.removeCartItem(item._id)}>x</span>
                                                        <img src={item.Images && item.Images[0]} className="img-fluid mimage" />

                                                    </Col>
                                                    <Col xs="8" className="no-pa-left">
                                                        <div className="title-imageMobile">
                                                            <h3>{item.Name}</h3>
                                                            <div className="type d-flex">
                                                                <h6>{item.Manufacturer}, {item.attributes && item.attributes['zemlja-porekla']}</h6>
                                                                <h6>{item.Package}</h6>
                                                            </div>

                                                        </div>
                                                        <div className="wrapper">
                                                            <div className="d-flex quantity">
                                                                <button className="btnn dec" onClick={() => this.DecreaseItem(idx)}>-</button>
                                                                <h2>{item.cartQuantity}</h2>
                                                                <button className="btnn inc" onClick={() => this.IncrementItem(idx)}>+</button>
                                                            </div>
                                                            <div className="price">
                                                                <h4>{this.props.shopCore.formatPrice(item.price * item.cartQuantity, 2)} RSD</h4>

                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                        )
                                    })
                                }





                                <div className="ukupno-price">
                                    <Row>

                                        <Col xs="6">
                                            <h4>Iznos bez PDV-a</h4>
                                        </Col>
                                        <Col xs="6" className="d-flex justify-content-end">
                                            <h4><span>{this.state.order ? this.props.shopCore.formatPrice(this.state.order.itemsTotal, 2) : 0} RSD</span></h4>
                                        </Col>
                                        <Col xs="12">
                                            <div className="hr"></div>
                                        </Col>
                                        <Col xs="6">
                                            <h6>Iznos PDV-a</h6>
                                        </Col>
                                        <Col xs="6" className="d-flex justify-content-end">
                                            <h6>{this.state.order ? this.state.order.tax : 0} RSD</h6>
                                        </Col>
                                        <Col xs="12">
                                            <div className="hr"></div>
                                        </Col>
                                        {/*<Col xs="6">
                                        <h6>Dostava</h6>
                                    </Col>
                                    <Col xs="6" className="d-flex justify-content-end">
                                        <h6>{this.state.order && this.state.order.DeliveryMethod ? this.state.order.DeliveryMethod.price : 0} RSD</h6>
                            </Col>
                                    <Col xs="12">
                                        <div className="hr"></div>
                                    </Col>*/}
                                        <Col xs="6">
                                            <h5>UKUPAN IZNOS</h5>
                                        </Col>
                                        <Col xs="6" className="d-flex justify-content-end">
                                            <h5><span>{this.state.order ? this.props.shopCore.formatPrice(this.state.order.total, 2) : 0} RSD</span></h5>
                                        </Col>
                                    </Row>
                                </div>
                                <p className="delivery-note">Cena dostave nije uračunata, detaljan cenovnik možete pogledati na <a href={this.state.order && this.state.order.DeliveryMethod.url} target="_blank" >sajtu izabrane pošte.</a></p>

                            </div>
                            {/*   end*/}


                            <div className="cartBox d-none d-lg-block d-md-block">
                                <Row>


                                    <Col xl="12" lg="12" md="12">
                                        <div className="title">
                                            <Row className="bottomB" noGutters >
                                                <Col xl="6" lg="6" md="5" xs="5"><h4>Artikal</h4></Col>
                                                <Col xl="4" lg="4" md="4" xs="4"><h4>Količina</h4></Col>
                                                <Col xl="2" lg="2" md="3" xs="3" className="d-flex justify-content-end"><h4>Cena</h4></Col>

                                            </Row>
                                        </div>
                                    </Col>


                                    {
                                        cartItems.map((item, idx) => {
                                            return (

                                                <Col key={idx} xl="12" lg="12" md="12">
                                                    <Row className="bottomB" noGutters>
                                                        <Col xl="2" md="3" lg="2" xs="2">
                                                            <div className="image d-flex">
                                                                <span onClick={() => this.removeCartItem(item._id)}>x</span>
                                                                <img src={item.Images && item.Images[0]} className="img-fluid cart-image" />
                                                            </div>
                                                        </Col>
                                                        <Col xl="5" md="4" lg="5" xs="5">
                                                            <div className="title-image">
                                                                <h3>{item.Name}</h3>
                                                                <p>{item.Manufacturer}, {item.attributes && item.attributes['zemlja-porekla']}</p>
                                                                <h2>{item.Package}</h2>
                                                            </div>
                                                        </Col>
                                                        <Col xl="2" md="2" lg="2" xs="2">
                                                            <div className="d-flex quantity">
                                                                <button className="btnn dec" onClick={() => this.DecreaseItem(idx)}>-</button>
                                                                <h2>{item.cartQuantity}</h2>
                                                                <button className="btnn inc" onClick={() => this.IncrementItem(idx)}>+</button>
                                                            </div>
                                                        </Col>
                                                        <Col xl="3" md="3" lg="3" xs="3">
                                                            <div className="price">
                                                                <h4>{this.props.shopCore.formatPrice(item.price * item.cartQuantity, 2)} RSD</h4>
                                                                <p>{this.props.shopCore.formatPrice(item.price, 2)} RSD po komadu</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>


                                            )
                                        })
                                    }


                                    {/*<Col xl="6" md="6" lg="6" xs="12" >
                                        <h1>UKUPAN IZNOS</h1>
                                    </Col>
                                    <Col xl="6" md="6" lg="6" xs="12" className="d-flex  justify-content-end">
                                        <h1><span>{this.props.shopCore.formatPrice(this.state.order ? this.state.order.itemsTotal : 0, 2)} RSD</span></h1>
                                    </Col>

                                    <Col xl="6" md="6" lg="6" xs="12" >
                                        <h1>UKUPAN IZNOS</h1>
                                    </Col>
                                    <Col xl="6" md="6" lg="6" xs="12" className="d-flex  justify-content-end">
                                        <h1><span>{this.props.shopCore.formatPrice(this.state.order ? this.state.order.itemsTotal : 0, 2)} RSD</span></h1>
                                    </Col>
                                */}
                                    <div className="total-price">
                                        <Row>

                                            <Col xl="6">
                                                <h4>Iznos bez PDV-a</h4>
                                            </Col>
                                            <Col xl="6" className="d-flex justify-content-end">
                                                <h4><span>{this.state.order ? this.props.shopCore.formatPrice(this.state.order.itemsTotal, 2) : 0} RSD</span></h4>
                                            </Col>

                                            <Col xl="12">
                                                <div className="hr"></div>
                                            </Col>

                                            <Col xl="6">
                                                <h6>Iznos PDV-a</h6>
                                            </Col>
                                            <Col xl="6" className="d-flex justify-content-end">
                                                <h6>{this.state.order ? this.state.order.tax : 0} RSD</h6>
                                            </Col>


                                            <Col xl="12">
                                                <div className="hr"></div>
                                            </Col>
                                            <Col xl="6">
                                                <h5>UKUPAN IZNOS</h5>
                                            </Col>
                                            <Col xl="6" className="d-flex justify-content-end">
                                                <h5><span>{this.state.order ? this.props.shopCore.formatPrice(this.state.order.total, 2) : 0} RSD</span></h5>
                                            </Col>
                                        </Row>
                                    </div>

                                    <Col lg="12">
                                        <p className="delivery-note">Cena dostave nije uračunata, detaljan cenovnik možete pogledati na <a href={this.state.order && this.state.order.DeliveryMethod.url} target="_blank" >sajtu izabrane pošte.</a></p>
                                    </Col>



                                </Row>

                            </div>

                            <div className="d-flex justify-content-end finishButton">
                                {!this.state.cartChanged ?

                                    cartItems.length ? <Link to={this.props.uData ? '/deliveryAddress' : '/cartLogin'}><Button>ZAVRŠI PORUDŽBINU</Button></Link> : null
                                    :
                                    <Button onClick={this.updateCurrentOrder}>OSVEŽI PORUDŽBINU</Button>

                                }
                            </div>
                        </Col>
                    </Row>
                </Container>


                <div className="whiteFooter">
                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

