import React, { Component } from 'react'
import decansko from '../images/decansko.png'
import vinaIzIstre from '../images/vinaizistre.png'
import { Link } from 'react-router-dom'
import {
    Container,
    Row,
    Col, Nav, NavItem, NavLink
} from 'reactstrap';
export class banner extends Component {
    render() {
        return (
            <div className="bannerSection">
                <Container>
                    <Row>
                        {console.log(this.props)}
                        <Col lg="6" md="6" xs="12">
                            <div className="bannerBoxWrapper">
                                {this.props.categories.map(item => {
                                    if (this.props.breadcrumb && this.props.breadcrumb[0] && this.props.breadcrumb[0].name == item.Name) {
                                        return (
                                            <img src={item.Banners && item.Banners[8] ? item.Banners[8].Image : null} className="img-fluid"></img>

                                        )
                                    }
                                })}
                            </div>
                        </Col>
                        <Col lg="6" md="6" xs="12">
                            <div className="bannerBoxWrapper">
                                {this.props.categories.map(item => {
                                    if (this.props.breadcrumb && this.props.breadcrumb[0] && this.props.breadcrumb[0].name == item.Name) {
                                        return (
                                            <img src={item.Banners && item.Banners[9] ? item.Banners[9].Image : null} className="img-fluid"></img>

                                        )
                                    }
                                })}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default banner
