import React from 'react';
import { Link } from 'react-router-dom';
import Navigacija from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import News from '../components/newsHomeComponent.js';
import DetailFooter from '../containers/detailFooter.js';


import slika1 from '../images/newsHome/slika1.png';
import slika2 from '../images/newsHome/slika2.png';
import slika3 from '../images/newsHome/slika3.png';

import {
    Container,
    Col,
    Row,
    Button
} from 'reactstrap';

const lat = "44.8134022";
const lang = "20.4547105";

export default class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
          }
      
    }

    componentWillMount() {
        window.scroll(0, 0);
    }

    componentDidMount() { window.scrollTo(0,0);
        this.props.shopCore.fetchNews({}, (data) => {
          //alert(JSON.stringify(data));
          this.setState({news: data});
        })
    
      }
    

    render() {
        const blog = 'blog';
        return (
            <>
                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> /  <span>Vesti i obaveštenja</span></h3>
                        <h2>VESTI I OBAVEŠTENJA</h2>

                    </div>
              
                    <News blog={blog} items = {this.state.news}></News>
                   
                </Container>
                <div className="googleMap">
                    <Map {...this.props} lat={lat} lang={lang}></Map>
                </div>
                <DetailFooter></DetailFooter>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}