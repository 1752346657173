import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';
import showResults from '../showResults.js';
import { renderTextField, renderTextareaField, email, required } from '../../fields/fields.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap'


const contactForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col xl="6" lg="6" md="6">
                    <Field
                        name="FirstName"
                        type="text"
                        component={renderTextField}
                        placeholder="Ime"
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl="6" lg="6" md="6">
                    <Field
                        name="EMail"
                        type="email"
                        component={renderTextField}
                        placeholder={"E-mail"}
                        validate={[required, email]}
                    ></Field>
                </Col>

                <Col lg="12">
                    <Field
                        name="Message"
                        type="text"
                        component={renderTextareaField}
                        placeholder="Poruka"
                        validate={[required]}
                    ></Field>
                </Col>


            </Row>

            <div className="d-flex justify-content-center">
                <button className="simpleButton">POŠALJI PORUKU</button>
            </div>

        </form>

    )
}

export default reduxForm({
    form: 'contactForm',  // a unique identifier for this form

})(contactForm)
