import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import showResults from '../showResults.js';
import { renderTextField, renderCheckField, email, required } from '../../fields/fields.js';

import {
    Row,
    Col,
    Button

} from 'reactstrap'


const newDeliverAdressForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    return (
        <form onSubmit={handleSubmit} >
            <Row>
                <Col xl={5}>
                    <Field
                        name="FirstName"
                        type="text"
                        component={renderTextField}
                        placeholder={'Ime'}
                        requiredField
                        validate={[required]}
                    ></Field>
                    <Field
                        name="EMail"
                        type="email"
                        component={renderTextField}
                        placeholder={'E-mail'}
                        requiredField
                        validate={[required, email]}
                    ></Field>

                    <Field
                        name="Street"
                        type="text"
                        component={renderTextField}
                        placeholder={'Ulica i broj'}
                        requiredField
                        validate={[required]}
                    ></Field>
             
                </Col>

                <Col xl={5}>
                <Field
                        name="LastName"
                        type="text"
                        component={renderTextField}
                        placeholder={'Prezime'}
                        requiredField
                        validate={[required]}
                    ></Field>
                    <Field
                        name="City"
                        type="text"
                        component={renderTextField}
                        placeholder={'Grad'}
                        requiredField
                        validate={[required]}
                    ></Field>


                    <Field
                        name="Zipcode"
                        type="text"
                        component={renderTextField}
                        placeholder={'Poštanski broj'}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

            </Row>

            <Button><span>SAČUVAJ</span></Button>


        </form>

    )
}

export default reduxForm({
    form: 'newDeliverAdressForm',  // a unique identifier for this form

})(newDeliverAdressForm)
