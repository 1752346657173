import React from 'react';
import { Link } from 'react-router-dom';


import Navigacija from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import ContactForm from '../components/forms/contactForm.js';

import {
    Col,
    Row,
    Button,
    Input,
    Container,
    FormGroup

} from 'reactstrap';

const lat = "44.8134022";
const lang = "20.4547105";

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.contact = this.contact.bind(this);

        this.state = {
            error: 'text'
        }
    }



    componentWillMount() {
        window.scroll(0, 0);
    }



    contact(data) {
        this.props.shopCore.contact(data, (data) => {
            console.log(data);
            if (data.successful) {
                this.setState({ message: 'Vaša poruka je uspešno poslata, očekujte odgovor ubrzo.' })
            }
        });
    }

    render() {
        return (
            <>
                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> /  <span>Kontakt</span></h3>
                    </div>
                </Container>
                <Container>
                    <div className="contactPage">
                        <h1>KONTAKT</h1>
                        <p>Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.</p>
                        <Row>
                            <Col xl="4" lg="4" md="4" xs="12">
                                <div className="whiteBox">
                                    <div className="content">
                                        <h4>Adresa</h4>
                                        <p>Skenderbegova 27</p>
                                        <p>11000 Beograd</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="4" lg="4" md="4" xs="12">
                                <div className="whiteBox">
                                    <div className="content">
                                        <h4>Telefon</h4>
                                        <p>telefon: +381 11 292 8506</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="4" lg="4" md="4" xs="12">
                                <div className="whiteBox">
                                    <div className="content">
                                        <h4>E-mail</h4>
                                        <p>veleprodaja@cerpromet.com</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </Container>
                <div className="form">
                    <Container>
                        <h1>KONTAKTIRAJTE NAS</h1>
                        {
                            this.state.message ?
                                <Row>
                                    <Col lg="12">
                                        <div className="alert alert-success" role="alert">
                                            {this.state.message}
                                        </div>
                                    </Col>
                                </Row>
                                :
                                <ContactForm onSubmit={this.contact}></ContactForm>

                        }

                    </Container>
                </div>
                <div className="googleMap">
                    <Map {...this.props} lat={lat} lang={lang}></Map>
                </div>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}