import React from 'react';
import mapIcon from '../images/pin_icon.svg';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.state = {};
    }

    initMap() {
        console.log("InitMAP");
        this.setState({
            _mapInit: true
        });
        var latLng = new window.google.maps.LatLng(this.props.lat || this.props.lat1 || this.props.lat2 || this.props.lat3 || this.props.lat4,
                                                 this.props.lang || this.props.lang1 || this.props.lang2 || this.props.lang3 || this.props.lang4);

        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 18,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "gestureHandling",

        });


        var marker = new window.google.maps.Marker({
            position: latLng,
            map: map,
            icon: mapIcon
        });

    }

    componentDidMount() {
        if (this.props.mapinit && !this.state._mapInit) {
            this.initMap();
        }

    }

    componentDidUpdate() {
        if (this.props.mapinit && !this.state._mapInit) {
            this.initMap();
        }
    }

    render() {
        return (
            <div>

                {
                    this.props.mapinit ?
                        <div className="map" ref={(input) => { this.GoogleMap = input; }}>
                            <Container>
                                <Row>

                                </Row>
                            </Container>
                        </div>
                        : null
                }

            </div>
        );
    }
}
