import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navigacija from '../../containers/navbar.js';
import Map from '../../containers/map.js';
import DetailFooter from '../../containers/detailFooter.js';
import Footer from '../../containers/footer.js';

import personalData from '../../images/profile/personalData.svg';
import orders from '../../images/profile/orders.svg';
import address from '../../images/profile/address.svg';
import wishes_icon from '../../images/profile/wishes.svg';
import password from '../../images/profile/password.svg';
import newsletter from '../../images/profile/newsletter.svg';

import profileImage from '../../images/profile/profileImage.png';


import PersonalData from '../../containers/formsComponent/personalData.js';
import Orders from '../../containers/formsComponent/orders.js';
import Address from '../../containers/formsComponent/address.js';
import Wishes from '../../containers/formsComponent/wishes.js';
import ChangePassword from '../../containers/formsComponent/changePassword.js';
import Newsletter from '../../containers/formsComponent/newsleter.js';

import {
    Container,
    Row,
    Col,
    FormGroup,
    Input

} from 'reactstrap';

const lat = "44.8134022";
const lang = "20.4547105";

export default class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: 'item1'
        };
    }

    componentDidMount(){
        if (this.props[0].match.params.item == 'orders'){
            this.setState({
                menu: 'item2'
            })
        }else if (this.props[0].match.params.item == 'wishlist'){
            this.setState({
                menu: 'item4'
            })
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps[0].location.pathname !== this.props[0].location.pathname){
            if (this.props[0].match.params.item == 'orders'){
                this.setState({
                    menu: 'item2'
                })
            }else if (this.props[0].match.params.item == 'wishlist'){
                this.setState({
                    menu: 'item4'
                })
            }
    
        }
    }

    render() {
        return (
            <>
                {
                    !this.props.uData ? <Redirect to='/login' ></Redirect> : null
                }

                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> / <span>Nalog</span></h3>
                    </div>
                </Container>

                <Container>

                    <Row>
                        <Col xl="12" xs="12" md="12" lg="12">
                            <div className="profileContent">
                                <Row>
                                    <Col xl="auto" lg="auto" md="auto" xs="auto">
                                        <div className="profileImage">
                                            {/*<img src={profileImage} className="img-fluid slika" />*/}

                                            <div className="char-image">
                                                <span>{this.props.uData ? this.props.uData.BillingAddress.FirstName[0]+this.props.uData.BillingAddress.LastName[0] : '' }</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="3" lg="3" md="4" xs="6">
                                        <div div className="name">
                                            <h1>{this.props.uData ? this.props.uData.User.Name : ''}</h1>
                                            <a onClick={() => this.props.setUserData(null)}>Odjavi se</a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col xl="3" lg="3" md="4" >
                            <div className="profileBox">
                                <div className="content">
                                    <h1>MOJ NALOG</h1>

                                    <li className={this.state.menu == 'item1' ? "activ" : ""}

                                        onClick={() => {
                                            this.setState({ menu: 'item1' });
                                        }}>
                                        <Isvg src={personalData} />Lični podaci</li>

                                    <li className={this.state.menu == 'item2' ? "activ" : ""}
                                        onClick={() => {
                                            this.setState({ menu: 'item2' });

                                        }}>
                                        <Isvg src={orders} />Moje narudžbe</li>


                                    <li className={this.state.menu == 'item3' ? "activ" : ""}
                                        onClick={() => {
                                            this.setState({ menu: 'item3' });
                                        }}>
                                        <Isvg src={address} />Adresa za isporuku</li>




                                    <li className={this.state.menu == 'item4' ? "activ" : ""}
                                        onClick={() => {
                                            this.setState({ menu: 'item4' });
                                        }}>
                                        <Isvg src={wishes_icon} />Lista želja</li>

                                    <li className={this.state.menu == 'item5' ? "activ" : ""}
                                        onClick={() => {
                                            this.setState({ menu: 'item5' });
                                        }}>
                                        <Isvg src={password} />Promena lozinke</li>

                                    <li
                                        className={this.state.menu == 'item6' ? "activ" : ""}
                                        onClick={() => {
                                            this.setState({ menu: 'item6' });
                                        }}>
                                        <Isvg src={newsletter} />Newsletter</li>

                                </div>
                            </div>
                        </Col>


                        {this.state.menu == 'item1' ?
                            <PersonalData {...this.props}></PersonalData>
                            : null}

                        {this.state.menu == 'item2' ?
                            <Orders {...this.props}></Orders>
                            : null}

                        {this.state.menu == 'item3' ?
                            <Address {...this.props}></Address>
                            : null}

                        {this.state.menu == 'item4' ?
                            <Wishes {...this.props}></Wishes>
                            : null}

                        {this.state.menu == 'item5' ?
                            <ChangePassword {...this.props}></ChangePassword>
                            : null}

                        {this.state.menu == 'item6' ?
                            <Newsletter {...this.props}></Newsletter>
                            : null}




                    </Row>
                </Container>




                <div className="googleMap">
                    <Map {...this.props} lat = {lat} lang={lang}></Map>
                </div>
                <DetailFooter></DetailFooter>
                <div className="whiteFooter">
                    <Footer {...this.props}></Footer>
                </div>

            </>
        );
    }
}