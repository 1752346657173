import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navigacija from '../containers/navbar';
import Carousel from '../containers/carousel';
import Pretraga from '../containers/search'
import Article from '../components/article';
import Banner from '../containers/banner';

import piceIcon from '../images/piceIcon.svg';
import background from '../images/background.png';

import bannerImg from '../images/banner2.png';
import Map from '../containers/map.js';
import DetailFooter from '../containers/detailFooter.js';
import Footer from '../containers/footer.js';
import News from '../components/newsHomeComponent.js';
import UniversalSlider from '../components/universalSlider.js';

import slika1 from '../images/newsHome/slika1.png';
import slika2 from '../images/newsHome/slika2.png';
import slika3 from '../images/newsHome/slika3.png';

import vine1 from '../images/vina/vine1.png';
import vine2 from '../images/vina/vine2.png';
import vine3 from '../images/vina/vine3.png';
import vine4 from '../images/vina/vine4.png';

import spirit1 from '../images/spirit/spirit1.png';
import spirit2 from '../images/spirit/spirit2.png';
import spirit3 from '../images/spirit/spirit3.png';
import spirit4 from '../images/spirit/spirit4.png';

import rakija1 from '../images/rakija/rakija1.png';
import rakija2 from '../images/rakija/rakija2.png';
import rakija3 from '../images/rakija/rakija3.png';
import rakija4 from '../images/rakija/rakija4.png';

import beer1 from '../images/beer/beer1.png';
import beer2 from '../images/beer/beer2.png';
import beer3 from '../images/beer/beer3.png';
import beer4 from '../images/beer/beer4.png';


import {
    Container,
    Row,
    Col, Nav, NavItem, NavLink, Button
} from 'reactstrap';
const news = [
    {
        _id: "1",
        image: slika1,
        date: "23",
        month: "Apr",
        title: "Lorem Ipsum dolor sit amet",
        fullDate: "24.05.2019. god.",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.",
        link: "/blogDetail"

    },
    {
        _id: "2",
        image: slika2,
        date: "23",
        month: "Apr",
        title: "Lorem Ipsum dolor sit amet",
        fullDate: "24.05.2019. god.",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.",
        link: "/blogDetail"

    },
    {
        _id: "3",
        image: slika3,
        date: "23",
        month: "Apr",
        title: "Lorem Ipsum dolor sit amet",
        fullDate: "24.05.2019. god.",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.",
        link: "/blogDetail"

    }
];

const vineJson = [
    {
        _id: 1,
        image: vine1,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/1',
        action: <div className="action"><p>-20%</p></div>
    },
    {
        _id: 3,
        image: vine2,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/2'
    }, {
        _id: 3,
        image: vine3,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/3'
    },
    {
        _id: 4,
        image: vine4,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/4'
    },
    {
        _id: 5,
        image: vine1,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/5'
    }

];

const spiritJson = [
    {
        _id: 1,
        image: spirit1,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/1'
    },
    {
        _id: 3,
        image: spirit2,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/2'
    }, {
        _id: 3,
        image: spirit3,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/3'
    },
    {
        _id: 4,
        image: spirit4,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/4'
    },
    {
        _id: 5,
        image: spirit1,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/5'
    }

];


const rakijaJson = [
    {
        _id: 1,
        image: rakija1,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/1'
    },
    {
        _id: 2,
        image: rakija2,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/2'
    }, {
        _id: 3,
        image: rakija3,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/3'
    },
    {
        _id: 4,
        image: rakija4,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/4'
    },
    {
        _id: 5,
        image: rakija1,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/5'
    }

];

const beerJson = [
    {
        _id: 1,
        image: beer1,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/1'
    },
    {
        _id: 2,
        image: beer2,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/2'
    }, {
        _id: 3,
        image: beer3,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/3'
    },
    {
        _id: 4,
        image: beer4,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/4'
    },
    {
        _id: 5,
        image: beer1,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/5'
    }

];

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            popular: [[], [], [], []],
            offer: [],
            selectedItems: 'bestSeller',
            news: [],
            slides: [],
            bestSeller: []
        };


    }
    componentWillMount() {
        window.scroll(0, 0);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.shopCore.fetchHomeProducts((data) => {
            this.setState(data);
        })
        this.props.shopCore.fetchNews({ count: 3 }, (data) => {
            //alert(JSON.stringify(data));
            this.setState({ news: data });
        })

        this.props.shopCore.fetchSlides((data) => {
            this.setState({ slides: data });
        });

    }
    componentDidUpdate(prevProps) {
        if (!prevProps.uData && this.props.uData) {
            this.props.shopCore.fetchHomeProducts((data) => {
                //alert(JSON.stringify(data));

                this.setState(data);
            })
            this.props.shopCore.fetchNews({ count: 3 }, (data) => {
                //alert(JSON.stringify(data));
                this.setState({ news: data });
            })

            this.props.shopCore.fetchSlides((data) => {
                this.setState({ slides: data });
            });
        }
    }


    render() {
        return (
            <div>
                <Navigacija {...this.props}></Navigacija>

                <Carousel {...this.props} ></Carousel>

                <Pretraga {...this.props}></Pretraga>

                <div className="bannerSection">
                    <Container>
                        <Row>
                            <Col lg="6" md="6" xs="12">
                                <div className="bannerBoxWrapper">
                                    <img src={this.props.settings.Banners && this.props.settings.Banners[0] && this.props.settings.Banners[0].Image} className="img-fluid"></img>
                                    {/* <Link to={this.props.settings.Banners && this.props.settings.Banners[0] && this.props.settings.Banners[0].Link ? this.props.settings.Banners[0].Link : '/'}><button className="button">DETALJNIJE</button></Link> */}

                                </div>
                            </Col>
                            <Col lg="6" md="6" xs="12">
                                <div className="bannerBoxWrapper">
                                    <img src={this.props.settings.Banners && this.props.settings.Banners[1] && this.props.settings.Banners[1].Image} className="img-fluid"></img>
                                    {/* <Link to={this.props.settings.Banners && this.props.settings.Banners[1] && this.props.settings.Banners[1].Link ? this.props.settings.Banners[1].Link : '/'}><button className="button">DETALJNIJE</button></Link> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="universalSlider">
                    <Container>
                        <Row>
                            <Col xl="12" lg="12" md="12">
                                <div className="heading">
                                    <Isvg src={piceIcon} />
                                    <h6>NAJPRODAVANIJE</h6>
                                    <p>Pića koja su najprodavanija</p>
                                </div>
                            </Col>
                            <UniversalSlider shopCore={this.props.shopCore} showInfoMessage={this.props.showInfoMessage} items={this.state.bestSeller}></UniversalSlider>
                        </Row>
                    </Container>
                </div>

                <div className="universalSlider">
                    <Container>
                        <Row>
                            <Col xl="12" lg="12" md="12">
                                <div className="heading">
                                    <Isvg src={piceIcon} />
                                    <h6>AKCIJA</h6>
                                    <p>Pića koja su na akciji</p>
                                </div>
                            </Col>
                            <UniversalSlider shopCore={this.props.shopCore} showInfoMessage={this.props.showInfoMessage} items={this.state.offer}></UniversalSlider>
                        </Row>
                    </Container>
                </div>


                <div className="universalSlider">
                    <Container>
                        <Row>
                            <Col xl="12" lg="12" md="12">
                                <div className="heading">
                                    <Isvg src={piceIcon} />
                                    <h6>PREPORUKE VINA</h6>
                                    <p>Pića koja preporucujemo</p>
                                </div>
                            </Col>
                            <UniversalSlider shopCore={this.props.shopCore} showInfoMessage={this.props.showInfoMessage} items={this.state.popular && this.state.popular[0]}></UniversalSlider>
                        </Row>
                    </Container>
                </div>


                <div className="universalSliderWrapper">
                    <img src={background} className="bg" />
                    <div className="universalSlider">
                        <Container>
                            <Row>
                                <Col xl="12" lg="12" md="12">
                                    <div className="heading">
                                        <Isvg src={piceIcon} />
                                        <h6>PREPORUKE SPIRITA</h6>
                                        <p>Pića koja preporucujemo</p>
                                    </div>
                                </Col>
                                <UniversalSlider shopCore={this.props.shopCore} showInfoMessage={this.props.showInfoMessage} items={this.state.popular && this.state.popular[1]}></UniversalSlider>
                            </Row>
                        </Container>
                    </div>
                </div>


                <div className="banner2">
                    <Container>
                        <Row>
                            <Col lg="12" md="12" xs="12">
                                <img src={this.props.settings.Banners && this.props.settings.Banners[2] && this.props.settings.Banners[2].Image} className="img-fluid" />
                            </Col>
                        </Row>
                    </Container>
                    <div className="bannerSection">
                        <Container>
                            <Row>
                                <Col lg="6" md="6" xs="12">
                                    <div className="bannerBoxWrapper">
                                        <img src={this.props.settings.Banners && this.props.settings.Banners[3] && this.props.settings.Banners[3].Image} className="img-fluid"></img>

                                    </div>
                                </Col>
                                <Col lg="6" md="6" xs="12">
                                    <div className="bannerBoxWrapper">
                                        <img src={this.props.settings.Banners && this.props.settings.Banners[4] && this.props.settings.Banners[4].Image} className="img-fluid"></img>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div className="universalSliderWrapper">
                    <img src={background} className="bg" />
                    <div className="universalSlider">
                        <Container>
                            <Row>
                                <Col xl="12" lg="12" md="12">
                                    <div className="heading">
                                        <Isvg src={piceIcon} />
                                        <h6>PREPORUKE RAKIJA</h6>
                                        <p>Pića koja preporucujemo</p>
                                    </div>
                                </Col>
                                <UniversalSlider shopCore={this.props.shopCore} showInfoMessage={this.props.showInfoMessage} items={this.state.popular && this.state.popular[2]}></UniversalSlider>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="universalSlider">
                    <Container>
                        <Row>
                            <Col xl="12" lg="12" md="12">
                                <div className="heading">
                                    <Isvg src={piceIcon} />
                                    <h6>PREPORUKE PIVA</h6>
                                    <p>Pića koja preporucujemo</p>
                                </div>
                            </Col>
                            <UniversalSlider shopCore={this.props.shopCore} showInfoMessage={this.props.showInfoMessage} items={this.state.popular && this.state.popular[3]}></UniversalSlider>
                        </Row>
                    </Container>
                </div>

                <div className="news_wrapper">

                    <h2>VESTI I OBAVEŠTENJA</h2>

                    <News items={this.state.news}></News>

                    <div className="d-flex justify-content-center">
                        <Link to="/blog"><Button>ARHIVA VESTI</Button></Link>
                    </div>
                </div>


                <DetailFooter></DetailFooter>
                <Footer {...this.props}></Footer>

            </div>
        )


    }
}