import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';
import showResults from '../showResults.js';
import { renderTextField, required, email, passwordsMustMatch } from '../../fields/fields.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap'


const changePasswordForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    return (
        <form onSubmit={handleSubmit} >
            <Row>
                <Col xl="5" xs="12" lg="5" md="12">
                    <Field
                        name="password"
                        type="password"
                        component={renderTextField}
                        placeholder={'Stara lozinka'}
                        validate={[required]}
                    ></Field>
                    <Field
                        name="new_password"
                        type="password"
                        component={renderTextField}
                        placeholder={'Lozinka'}
                        validate={[required]}
                    />
                    <Field
                        name="repeat_password"
                        type="password"
                        component={renderTextField}
                        placeholder={'Ponovite lozinku'}
                        validate={[required]}
                    />
                </Col>
            </Row>

            <button className="btn"><span>SAČUVAJ</span></button>


        </form>


    )
}

export default reduxForm({
    form: 'changePasswordForm',  // a unique identifier for this form

})(changePasswordForm)
