import React from 'react';
import { Link } from 'react-router-dom';

import pice from '../../images/profile/favorite.png';

import {
    Col,
    Container,
    Row,
    Button,
    FormGroup,
    Input
} from 'reactstrap';

export default class Wishes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: []

        }
    }

    componentDidMount() { window.scrollTo(0,0);
        this.props.shopCore.wishlist((data) => {
            this.setState({
                products: data
            })

        })
    }

    handleDelete(id){
        this.props.shopCore.removeFromWishlist({_id: id}, () => {
            this.props.shopCore.wishlist((data) => {
                this.setState({
                    products: data
                })
    
            })
    
        })
    }



    render() {
        return (
            <>
                <Col xl="9" md="8" lg="9">
                    <div className="personalData">
                        <div className="personalBox">
                            <h1>LISTA OMILJENIH</h1>
                            <Row>
                                {
                                    this.state.products.map((item, idx) => {
                                        return (
                                            <Col xl="3" md="6" lg="3" xs="6">
                                            <div className="favoriteBox">
                                            <Link to={`/product/${item._id}/${item.Alias}`}><img src={item.Images && item.Images.length && item.Images[0]} className="img-fluid" /></Link>
                                                <div className="content">
                                                <Link to={`/product/${item._id}/${item.Alias}`}><h3>{item.Name}</h3></Link>
                                                    <h4>2100 RSD</h4>
                                                    <Link to="#"><Button>U KORPU</Button></Link>
                                                </div>
                                            </div>
                                        </Col>
        
                                        )
                                    })
                                }


                            </Row>

                        </div>
                    </div>
                </Col>
            </>
        );
    }
}