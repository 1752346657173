import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import showResults from '../showResults.js';
import { renderTextField, renderCheckField, email, required } from '../../fields/fields.js';

import {
    Row,
    Col,
    Button

} from 'reactstrap'


const personalDataForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    return (
        <form onSubmit={handleSubmit} >
            <Row>
            <Col xl="5">
                <Field
                    name="EMail"
                    type="email"
                    component={renderTextField}
                    requiredField
                    placeholder="E-mail"
                    validate={[required, email]}
                ></Field>


                <Field
                    name="FirstName"
                    type="text"
                    component={renderTextField}
                    requiredField
                    placeholder="Ime"
                    validate={[required]}
                ></Field>


                <Field
                    name="LastName"
                    type="text"
                    component={renderTextField}
                    requiredField
                    placeholder="Prezime"
                    validate={[required]}
                ></Field>
            </Col>
            <Col xl="5">
                <Field
                    name="Company"
                    type="text"
                    placeholder="Firma"
                    component={renderTextField}
                ></Field>

                <Field
                    name="City"
                    type="text"
                    component={renderTextField}
                    requiredField
                    placeholder="Grad"
                    validate={[required]}
                ></Field>

                <Field
                    name="Street"
                    type="text"
                    component={renderTextField}
                    requiredField
                    placeholder="Ulica i broj"
                    validate={[required]}
                ></Field>

                <Field
                    name="Zipcode"
                    type="text"
                    component={renderTextField}
                    requiredField
                    placeholder="Poštanski broj"
                    validate={[required]}
                ></Field>

                <Field
                    name="Phone"
                    type="text"
                    component={renderTextField}
                    requiredField
                    placeholder="Telefon"
                    validate={[required]}
                ></Field>
            </Col>
            </Row>
            <Button><span>SAČUVAJ</span></Button>


        </form>

    )
}

export default reduxForm({
    form: 'personalDataForm',  // a unique identifier for this form

})(personalDataForm)
