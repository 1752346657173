import React, { Component } from 'react'
import Isvg from 'react-inlinesvg'
import facebook from '../images/fb.svg'
import heart from '../images/heart.svg'
import instagram from '../images/instagram.svg'
import phone from '../images/phone.svg'
import shoppingCart from '../images/shoppingCart.svg'
import { Link } from 'react-router-dom'
import logo from '../images/logo.svg'
import Sidebar from "react-sidebar";
import account_icon from '../images/account_icon.svg';

import hamburger from '../images/hamburger.svg'
import { NavLink as RRNavLink } from 'react-router-dom';
import RightNavbar from '../containers/slidenav.js';

import {
    Container,
    Row,
    Col,
    NavItem, Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    InputGroup, Button
} from 'reactstrap';

export class Navigacija extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false

        };
    }

    componentWillMount() {
        window.scroll(0, 0);
    }

    render() {

        return (
            <div className="header">
                <div className="headerTop">
                    <Container>
                        <Row>
                            <Col xl="2" lg="2" md="3" xs="6">
                                <p>Pratite nas
                                    <a href="https://facebook.com/cerpromet/" target="_blank"><Isvg src={facebook} className="facebook" /></a>
                                    <a href="https://www.instagram.com/cerpromet1/" target="_blank">  <Isvg src={instagram} className="instagram" /></a>
                                </p>
                            </Col>
                            <Col xl="2" lg="2" md="3" xs="6" className="text-right text-lg-left text-xl-left">
                                <p><Isvg src={phone} className="phone" /><a href="tel://+381 11 2928 506"> +381 11 2928 506</a></p>
                            </Col>
                            <Col xl="3" lg="4" md="4" className="offset-xl-5 offset-lg-4 offset-md-2 d-none d-md-block d-lg-block">
                                {this.props.uData ?
                                    <div className="loginHeder">
                                        <Link to="/profile">NALOG</Link><Link to='/profile/wishlist'><Isvg src={heart} className="heart" /></Link>

                                        <Link to="/cart"><div className="cart-icon"><Isvg src={shoppingCart} /> <div className="cart-count">{this.props.cartCount}</div> </div></Link>
                                    </div>

                                    :
                                    <div className="loginHeder">
                                        <Link to="/login">PRIJAVA</Link>&nbsp;&nbsp;/&nbsp;&nbsp;<Link to="/register">REGISTRACIJA</Link><Link to='/profile/wishlist'><Isvg src={heart} className="heart" /></Link>
                                        <Link to="/cart"><div className="cart-icon"><Isvg src={shoppingCart} /> <div className="cart-count">{this.props.cartCount}</div> </div></Link>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <Row className="d-flex justify-content-between justify-content-md-start d-lg-none mt-3 mb-3 align-items-center">
                        <Col xs="2" md="3">
                            <button className="btn-menu" onClick={() => this.setState({ isOpen: !this.state.isOpen })}><i className="fa fa-bars"></i></button>
                        </Col>
                        <Col xs="7" md="6">
                            <Link to="/"><Isvg src={logo} className="mobileLogo" /></Link>
                        </Col>
                        <Col xs="2" className="d-md-none">
                            <Link to="/cart"><div className="cart-icon"><Isvg src={shoppingCart} className="shoppingCart" /> <div className="cart-count">{this.props.cartCount}</div></div></Link>
                        </Col>
                    </Row>
                </Container>




                <div className="desktopNavbar">
                    <Container>
                        <Navbar expand="lg" className="d-none d-lg-flex ">
                            <Nav className="lijevi" navbar>
                                <NavItem>
                                    <NavLink to="/" exact tag={RRNavLink} className="nav-link" activeClassName="active">POČETNA</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/about" exact tag={RRNavLink} className="nav-link" activeClassName="active">O NAMA</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/recommendations" tag={RRNavLink} className="nav-link" activeClassName="active">PREPORUKE</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/blog" exact tag={RRNavLink} className="nav-link" activeClassName="active">BLOG</NavLink>
                                </NavItem>
                            </Nav>

                            <NavbarBrand className="d-md-flex d-lg-flex"> <Link to="/"><Isvg src={logo} /></Link> </NavbarBrand>

                            <Nav className="desni" navbar>
                                <NavItem>
                                    <NavLink to="/shopLocations" exact tag={RRNavLink} className="nav-link" activeClassName="active">PRODAVNICE</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/delivery" exact tag={RRNavLink} className="nav-link" activeClassName="active">DOSTAVA</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/contact" exact tag={RRNavLink} className="nav-link" activeClassName="active">KONTAKT</NavLink>
                                </NavItem>
                            </Nav>
                        </Navbar>

                        {/*                        <div className="d-md-none d-lg-none mobileNavbar">

                            <Sidebar
                                sidebar={<b>Sidebar content</b>}
                                open={this.state.sidebarOpen}
                                onSetOpen={this.onSetSidebarOpen}
                                styles={{ sidebar: { background: "white" } }}
                            >
                                
                                    <Isvg onClick={() =>this.onSetSidebarOpen(true)} src={hamburger}/>
                                
                            </Sidebar>
                        </div> */}
                    </Container>
                </div>

                <Container className="categoryMenu">
                    <Nav className="d-flex justify-content-between">
                        {
                            this.props.categories.map((item, idx) => {
                                return (
                                    <NavItem key={idx}>
                                        <NavLink to={`/category/${item.Alias}`} exact tag={RRNavLink} className="nav-link" activeClassName="active">{item.Name}</NavLink>
                                    </NavItem>
                                )
                            })
                        }
                    </Nav>

                </Container>

                <div className={this.state.isOpen ? 'navbarResposniveOpen' : 'navbarResposnive'} onMouseDown={() => this.setState({ isOpen: !this.state.isOpen })}>
                    <div className={this.state.isOpen ? 'sadrzajOpen' : 'sadrzaj'} onMouseDown={this.state.isOpen}>
                        <div className="content">
                            <div className="user-area">
                                <Col xl="4" lg="4" md="4" className="account">
                                    <Isvg src={account_icon} />
                                    {this.props.uData ?
                                        <p><Link to="/profile" className="nav-link">Nalog</Link></p>
                                        :
                                        <p><Link to="/login" className="nav-link">Prijava</Link> / <Link to="/register" className="nav-link">Registracija</Link></p>
                                    }
                                </Col>
                            </div>
                            <NavItem className="nav-item">
                                <Link className="nav-link" to="/">POČETNA</Link>
                            </NavItem>
                            <NavItem className="nav-item">
                                <Link className="nav-link" to="/about">O NAMA</Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link" to="/recommendations">PREPORUKE</Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link" to="/blog">BLOG</Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link" to="/shopLocations">PRODAVNICE</Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link" to="/delivery">DOSTAVA</Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link" to="/contact">KONTAKT</Link>
                            </NavItem>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}

export default Navigacija


