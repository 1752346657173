import React from 'react';
import { Link } from 'react-router-dom';

import {
    Col,
    Container,
    Row,
    Button,
    FormGroup,
    Input
} from 'reactstrap';
import moment from 'moment';

export default class PersonalData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: []
        }
    }

    componentDidMount() { window.scrollTo(0,0);
        this.props.shopCore.fetchOrders((data) => {
            this.setState({
                orders: data
            })

        })
    }

    render() {
        return (
            <>
                <Col xl="9" lg="9" md="8">
                    <div className="personalData">
                        <div className="personalBox">
                            <h1>MOJE NARUDŽBE</h1>
                            
                            <Row className="bottom-border">

                                <Col xl="4" md="4" lg="4" xs="3">
                                   <p><span> Broj narudžbe</span></p>
                                    </Col>
                                <Col xl="3" md="3" lg="3" xs="3">
                                  <p><span>   Datum</span></p>
                                    </Col>
                                <Col xl="3" md="2" lg="3" xs="3"> 
                                  <p><span>   Iznos</span> </p>
                                    </Col>
                                <Col xl="2" md="3" lg="2" xs="2">
                                   <p><span>  Status</span></p>
                                    </Col>

                            </Row>

                               {
                                                this.state.orders.map((item, idx) => {
                                                    return (
                                                        <Row className="bottom-border datas">

                                                        <Col xl="4" md="3" lg="4" xs="3">
                                                           <p>{item.orderNumber}</p>
                                                         </Col>
                                                        <Col xl="3" md="3" lg="3" xs="3">
                                                           <p> {moment.unix(item.orderTime).format('DD.MM.YYYY')}</p>
                                                        </Col>
                                                        <Col xl="3" md="3" lg="3" xs="3">
                                                          <p>  {this.props.shopCore.formatPrice(item.total)} RSD</p>
                                                        </Col>
                                                        <Col xl="2" md="3" lg="2" xs="2">
                                                           <p> {item.Status}</p>
                                                         </Col>
                        
                                                    </Row>
                                
                                                    )
                                                })
                                            }
                        </div>
                    </div>
                </Col>
            </>
        );
    }
}