import React from 'react';
import {Link} from 'react-router-dom';
import PersonalDataForm from '../../components/forms/personalDataForm.js';

import {
    Col,
    Container,
    Row,
    Button,
    FormGroup,
    Input
} from 'reactstrap';

export default class PersonalData extends React.Component {
    constructor(props) {
        super(props);
        this.editAccount = this.editAccount.bind(this);

        this.state = {
            initialValues: null
        };

    }

    componentDidMount() { window.scrollTo(0,0);
        if (this.props.uData && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.BillingAddress.FirstName,
                    LastName: this.props.uData.BillingAddress.LastName,
                    Street: this.props.uData.BillingAddress.Street,
                    Zipcode: this.props.uData.BillingAddress.Zipcode,
                    City: this.props.uData.BillingAddress.City,
                    Country: this.props.uData.BillingAddress.Country,
                    EMail: this.props.uData.BillingAddress.EMail,
                    Phone: this.props.uData.BillingAddress.Phone,
                    Company: this.props.uData.BillingAddress.Company,

                }
            })
        }

    }
    componentDidUpdate(prevProps){
        console.log(this.state.initialValues);
        if (this.props.uData && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.BillingAddress.FirstName,
                    LastName: this.props.uData.BillingAddress.LastName,
                    Street: this.props.uData.BillingAddress.Street,
                    Zipcode: this.props.uData.BillingAddress.Zipcode,
                    City: this.props.uData.BillingAddress.City,
                    Country: this.props.uData.BillingAddress.Country,
                    EMail: this.props.uData.BillingAddress.EMail,
                    Phone: this.props.uData.BillingAddress.Phone,
                    Company: this.props.uData.BillingAddress.Company,

                }
            })
        }
    }

    editAccount(data) {
        let obj = {
            'User.Name': data.FirstName + " " + data.LastName,
            'User.EMail': data.EMail,
            'BillingAddress.FirstName': data.FirstName,
            'BillingAddress.LastName': data.LastName,
            'BillingAddress.Street': data.Street,
            'BillingAddress.Zipcode': data.Zipcode,
            'BillingAddress.City': data.City,
            'BillingAddress.EMail': data.EMail,
            'BillingAddress.Phone': data.Phone,
            'BillingAddress.Company': data.Company,

        }

        //this.props.socketIOClient.emit("updateUserData", obj);
        this.props.shopCore.updateUserData(obj, (data) => {
            if (data.successful) {
                this.props.setUserData(data.user);
            }
        })
    }

    render() {
        return (
            <>
                <Col xl="9" md="8" lg="9">
                    <div className="personalData">
                        <div className="personalBox">
                            <h1>LIČNI PODACI</h1>
                            <PersonalDataForm onSubmit={this.editAccount} initialValues={this.state.initialValues}></PersonalDataForm>
                        </div>
                    </div>
                </Col>
            </>
        );
    }
}