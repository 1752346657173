import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Col, Row, Button } from 'reactstrap';

export default class RecommendationsItem extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>

                <Col xl="12" lf="12" md="12" xs="12">
                    <div className="recommendation">
                        <div className="content">
                            <Row>

                                <Col xl="3" lg="4" md="4" xs="12" className="no-pr">
                                    <img src={this.props.Images && this.props.Images[0]} className="img-fluid" />
                                </Col>

                                <Col xl="8" lg="8" md="8" xs="12" className="offset-xl-1">
                                    <h1>{this.props.Name}</h1>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.ShortDescription }}></div>
                                    {
                                        this.props.attrs && this.props.attrs.map((attr, idx) => {
                                            return (
                                                <p><span>{attr.name}:</span> {attr.value}</p>

                                            )
                                        })
                                    }

                                    <div className="float-left">
                                        <h4>{this.props.price}</h4>
                                    </div>
                                    <div className="float-right">
                                        <Link to={`/product/${this.props._id}/${this.props.Alias}`}><Button>DETALJNIJE</Button></Link>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                        <p>{this.props.description}</p>
                    </div>

                </Col>
            </>
        );
    }
}
