import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';
import showResults from '../showResults.js';
import { renderTextField, renderTextareaField, renderCheckField, email, required } from '../../fields/fields.js';

import {
    Row,
    Col,
    Button
} from 'reactstrap'


const forgotPasswordForma = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    return (
        <form onSubmit={handleSubmit} >
                    <Field
                        name="email"
                        type="email"
                        component={renderTextField}
                        label={('Email')}
                        placeholder={'E-Mail'}
                        validate={[required, email]}
                    ></Field>
            <Button><span>POŠALJI</span></Button>
        </form>

    )
}

export default reduxForm({
    form: 'forgotPasswordForma',  // a unique identifier for this form

})(forgotPasswordForma)
