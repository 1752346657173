import React from 'react';
import { Link } from 'react-router-dom';

import Navigacija from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import Blog from '../components/newsHomeComponent.js';
import blogDetailImg from '../images/blogDetail.png';
import DetailFooter from '../containers/detailFooter.js';


import slika1 from '../images/newsHome/slika1.png';
import slika2 from '../images/newsHome/slika2.png';
import slika3 from '../images/newsHome/slika3.png';
import DocumentMeta from 'react-document-meta';

import {
    Container,
    Col,
    Row
} from 'reactstrap';
import moment from 'moment';
const striptags = require('striptags');

const news = [
    {
        _id: "1",
        image: slika1,
        date: "23",
        month: "Apr",
        title: "Lorem Ipsum dolor sit amet",
        fullDate: "24.05.2019. god.",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.",
        link: "/blogDetail"

    },
    {
        _id: "2",
        image: slika2,
        date: "23",
        month: "Apr",
        title: "Lorem Ipsum dolor sit amet",
        fullDate: "24.05.2019. god.",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.",
        link: "/blogDetail"

    },
    {
        _id: "3",
        image: slika3,
        date: "23",
        month: "Apr",
        title: "Lorem Ipsum dolor sit amet",
        fullDate: "24.05.2019. god.",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.",
        link: "/blogDetail"

    }
];

export default class BlogDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            item: {}
        }
    }
    componentWillMount() {
        window.scroll(0, 0);
    }




    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.shopCore.fetchNews({ count: 2 }, (data) => {
            //alert(JSON.stringify(data));
            this.setState({ news: data });
        })
        this.props.shopCore.fetchNewsItem({ _id: this.props[0].match.params.id }, (data) => {
            //alert(JSON.stringify(data));
            this.setState({ item: data });
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props[0].match.params.id != prevProps[0].match.params.id) {
            this.props.shopCore.fetchNewsItem({ _id: this.props[0].match.params.id }, (data) => {
                //alert(JSON.stringify(data));
                window.scrollTo(0, 0);

                this.setState({ item: data });
            })

        }
    }

    render() {
        const meta = {
            title:  this.state.item.Title ? (this.state.item.Title + ' | Cerpromet - Prodaja i distribucija vina, rakija i alkoholnih pića, maloprodaja i veleprodaja vina, distributeri vina, vinoteka u Beogradu, domaće vinarije, manastirska vina i rakije, domaća vina i rakije') : 'Cerpromet - Prodaja i distribucija vina, rakija i alkoholnih pića, maloprodaja i veleprodaja vina, distributeri vina, vinoteka u Beogradu, domaće vinarije, manastirska vina i rakije, domaća vina i rakije',
            description: this.state.item.ShortDescription,
            meta: {
                charset: 'utf-8',
                property: {
                    'og:url': `https://cerpromet.com/blog/${this.state.item._id}/${this.state.item.Alias}`,
                    'og:title': this.state.item.Title ? (this.state.item.Title + ' | Cerpromet - Prodaja i distribucija vina, rakija i alkoholnih pića, maloprodaja i veleprodaja vina, distributeri vina, vinoteka u Beogradu, domaće vinarije, manastirska vina i rakije, domaća vina i rakije') : 'Cerpromet - Prodaja i distribucija vina, rakija i alkoholnih pića, maloprodaja i veleprodaja vina, distributeri vina, vinoteka u Beogradu, domaće vinarije, manastirska vina i rakije, domaća vina i rakije',
                    'og:image': this.state.item.Image,
                    'og:description':  this.state.item.ShortDescription,

                }
            }
        };

        const blogDetail = "blogDetail";
        return (
            <div>
                <DocumentMeta {...meta} />
                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> /  <span>Vesti i obaveštenja</span></h3>
                        <h1>{this.state.item.Title}</h1>

                    </div>
                </Container>
                <Container>
                    <Row>
                        <Col xl="8" lg="8" md="8" xs="12">
                            <div className="left">
                                <h6>{moment.unix(this.state.item.time).format('DD.MM.YYYY.')} god.</h6>
                                <div dangerouslySetInnerHTML={{__html: this.state.item.ShortDescription}}></div>
                                <br/>
                                <img src={this.state.item.Image} className="img-fluid" />
                                <div dangerouslySetInnerHTML={{__html: this.state.item.Content}}></div>
                            </div>

                        </Col>
                        <Col xl="4" lg="4" md="4" xs="12" className="no-pa">
                            <div className="right">
                                <h3>Poslednje vesti</h3>
                            </div>
                            <Blog blogDetail={blogDetail} items={this.state.news}></Blog>
                        </Col>
                    </Row>
                </Container>


              

                <DetailFooter></DetailFooter>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}