import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navigacija from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';
import arrow from '../../images/shopingCart/redDropArrow.svg';
import NewDeliverAdressForm from '../../components/forms/newDeliveryAdressForm.js';


import {
    Col, Row, Button, Container, FormGroup, Input, Label
} from 'reactstrap';

export class DeliveryAddress extends Component {

    constructor(props) {
        super(props);
        this.newDeliveryAddress = this.newDeliveryAddress.bind(this);

        this.state = {

        }
    }

    newDeliveryAddress(data) {
        console.log(data);
        let obj = {
            'FirstName': data.FirstName,
            'LastName': data.LastName,
            'Street': data.Street,
            'Zipcode': data.Zipcode,
            'City': data.City,
            'EMail': data.EMail,
        }

        let arr = this.props.uData.ShippingAddress;
        if (!arr) {
            arr = [];
        }

        arr.push(obj);


        this.props.shopCore.updateCurrentOrder({
            BillingAddress: this.props.uData.BillingAddress,
            ShippingAddress: obj
        }, (data) => {
            this.props.shopCore.updateUserData({
                ShippingAddress: arr
            }, (data) => {
                if (data.successful) {
                    this.props.setUserData(data.user);
    
    
    
                    /*if (this.state.redirect) {
                        this.props[0].history.push('/nalog');
                    }*/
                }
            })
        });


        

    }


    render() {
        let currentOrder = this.props.uData ? this.props.uData.CurrentOrder : {};

        return (
            <div>
                <Navigacija {...this.props} />
                <Container>
                    <div className="loginguest">
                        <Row>
                            <Col xl="12" md="12" lg="12">
                                <h2>ADRESA ZA ISPORUKU</h2>
                                <div className="deliveryAddress ">

                                    <FormGroup>
                                        <Input type="select" name="select" onChange={(e) => {
                                            console.log(e.target.value)
                                            this.props.shopCore.updateCurrentOrder({
                                                BillingAddress: this.props.uData.BillingAddress,
                                                ShippingAddress: this.props.uData.ShippingAddress[e.target.value]
                                            }, (data) => {

                                            });

                                        }} id="exampleSelect">
                                            <option disabled selected>Izaberite adresu</option>
                                            {
                                                this.props.uData && this.props.uData.ShippingAddress && this.props.uData.ShippingAddress.map((item, idx) => {
                                                    return (
                                                        <option key={idx} selected={
                                                            currentOrder &&
                                                            currentOrder.ShippingAddress &&
                                                            currentOrder.ShippingAddress.FirstName == item.FirstName &&
                                                            currentOrder.ShippingAddress.LastName == item.LastName &&
                                                            currentOrder.ShippingAddress.City == item.City &&
                                                            currentOrder.ShippingAddress.Street == item.Street &&
                                                            currentOrder.ShippingAddress.Zipcode == item.Zipcode ? true : false
                                                        }  value={idx}>{item.FirstName} {item.LastName}, {item.Street}, {item.City} {item.Zipcode}</option>

                                                    )
                                                })}
                                        </Input>
                                        <Isvg src={arrow} />
                                    </FormGroup>

                                    <Link to="/overviewCart"><button className="unibutton">NASTAVI</button></Link>

                                </div>


                                <h3>NOVA ADRESA</h3>
                                <div className="fields">
                                    <NewDeliverAdressForm onSubmit={this.newDeliveryAddress}></NewDeliverAdressForm>
                                </div>

                            </Col>

                        </Row>
                    </div>
                </Container>

                <div className="whiteFooter">
                    <Footer {...this.props}/>
                </div>
            </div>
        )
    }
}

export default DeliveryAddress
