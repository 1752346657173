import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Navigacija from '../../containers/navbar';
import Footer from '../../containers/footer';
import CartImg from '../../images/shopingCart/cart.png';


import {
    Col, Row, Container, Button, FormGroup, Input, Label
} from 'reactstrap';

export class OrderDone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.shopCore.fetchOrder({ orderId: this.props[0].match.params.oid }, (data) => {
            console.log(data);
            if (window.fbq){
                window.fbq('track', 'Purchase', {value: data.total, currency: 'RSD'});
            }
            if (window.gtag){
                window.gtag('event', 'conversion', {'send_to': 'AW-721797893/hDB8CIen8MwBEIWGl9gC'});
            }
            this.setState({
                order: data,
            });
        });
    }




    render() {
        let cartItems = this.state.order ? this.state.order.products : [];

        return (
            <div>
                <Navigacija {...this.props} />

                <Container>
                    <div className="owerviewHeading">

                        <div className="box-overwiew payment-overview">
                                <h1>Vaša narudžba je uspešno primljena.</h1>
                            {this.state.order && this.state.order.BillingAddress && this.state.order.ShippingAddress ?

                                <Row>

                                    <Col xl="6" lg="6" md="6" xs="12">
                                        <h4>Adresa za obračun</h4>
                                        <table>
                                            <tr>
                                                <td>Ime</td>
                                                <td>{this.state.order.BillingAddress.FirstName} {this.state.order.BillingAddress.LastName}</td>
                                            </tr>
                                            <tr>
                                                <td>Ulica</td>
                                                <td>{this.state.order.BillingAddress.Street}</td>
                                            </tr>
                                            <tr>
                                                <td>Grad</td>
                                                <td>{this.state.order.BillingAddress.Zipcode} {this.state.order.BillingAddress.City}</td>
                                            </tr>
                                            <tr>
                                                <td>EMail</td>
                                                <td>{this.state.order.BillingAddress.EMail}</td>
                                            </tr>

                                        </table>
                                    </Col>
                                    <Col xl="6" lg="6" md="6" xs="12">
                                        <h4>Adresa za dostavu</h4>
                                        <table>
                                            <tr>
                                                <td>Ime</td>
                                                <td>{this.state.order.ShippingAddress.FirstName} {this.state.order.ShippingAddress.LastName}</td>
                                            </tr>
                                            <tr>
                                                <td>Ulica</td>
                                                <td>{this.state.order.ShippingAddress.Street}</td>
                                            </tr>
                                            <tr>
                                                <td>Grad</td>
                                                <td>{this.state.order.ShippingAddress.Zipcode} {this.state.order.ShippingAddress.City}</td>
                                            </tr>
                                            <tr>
                                                <td>EMail</td>
                                                <td>{this.state.order.ShippingAddress.EMail}</td>
                                            </tr>

                                        </table>
                                    </Col>

                                    <Col xl="6" lg="6" md="6" xs="12">

                                        <h4>Podaci o trgovcu</h4>
                                        <table>
                                            <tr>
                                                <td>Naziv</td><td>Cerpromet d.o.o.</td>
                                            </tr>
                                            <tr>

                                                <td>PIB</td><td> 100046775</td>
                                            </tr>
                                            <tr>

                                                <td>Adresa </td><td>Skenderbegova br. 27</td>
                                            </tr>
                                        </table>
                                    </Col>

                                </Row>

                                : null
                            }
                        </div>


                        <div className="order-prewiev d-none d-lg-block d-md-block">
                            <div className="title">
                                <Row className="bottomB">
                                    <Col xl="6" lg="6" md="6" xs="6">
                                        <h6>Artikal</h6>
                                    </Col>  <Col xl="3" lg="3" md="3" className="d-flex justify-content-end">
                                        <h6>Količina</h6>
                                    </Col>  <Col xl="3" lg="3" md="3" className="d-flex justify-content-end">
                                        <h6>Cena</h6>
                                    </Col>
                                </Row>
                            </div>

                            {
                                cartItems.map((item, idx) => {
                                    return (
                                        <div className="content-prewiev" key={idx}>
                                            <Row >
                                                <Col xl="6" lg="6" md="6" className="d-flex item">
                                                    <img src={item.Images && item.Images[0]} className="img-fluid" />
                                                    <div className="title-image">
                                                        <h3>{item.Name}</h3>
                                                        <p>{item.Manufacturer}, {item.attributes && item.attributes['zemlja-porekla']}</p>
                                                        <h2>{item.Package}</h2>
                                                    </div>
                                                </Col>
                                                <Col xl="3" lg="3" md="3">
                                                    <div className="d-flex justify-content-end quantity " >
                                                        <h2>{item.cartQuantity}</h2>
                                                    </div>
                                                </Col>
                                                <Col xl="3" lg="3" md="3">
                                                    <div className="price d-block justify-content-end">
                                                        <h4>{this.props.shopCore.formatPrice(item.price * item.cartQuantity, 2)} RSD</h4>
                                                        <p>{this.props.shopCore.formatPrice(item.price, 2)} RSD po komadu</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                    )
                                })
                            }

                            <div className="total-price">
                                <Row>

                                    <Col xl="6">
                                        <h4>Iznos bez PDV-a</h4>
                                    </Col>
                                    <Col xl="6" className="d-flex justify-content-end">
                                        <h4><span>{this.state.order ? this.props.shopCore.formatPrice(this.state.order.itemsTotal, 2) : 0} RSD</span></h4>
                                    </Col>

                                    <Col xl="12">
                                        <div className="hr"></div>
                                    </Col>

                                    <Col xl="6">
                                        <h6>Iznos PDV-a</h6>
                                    </Col>
                                    <Col xl="6" className="d-flex justify-content-end">
                                        <h6>{this.state.order ? this.state.order.tax : 0} RSD</h6>
                                    </Col>


                                    <Col xl="12">
                                        <div className="hr"></div>
                                    </Col>
                                    <Col xl="6">
                                        <h5>UKUPAN IZNOS</h5>
                                    </Col>
                                    <Col xl="6" className="d-flex justify-content-end">
                                        <h5><span>{this.state.order ? this.props.shopCore.formatPrice(this.state.order.total, 2) : 0} RSD</span></h5>
                                    </Col>
                                </Row>
                            </div>

                        </div>


                        {/*mobile version */}


                        <div className="wrapperMobleCart  d-md-none d-lg-none">
                            {
                                cartItems.map((item, idx) => {
                                    return (
                                        <div className="cartBoxMobile" key={idx}>
                                            <Row>
                                                <Col xs="4" className="d-flex">
                                                    <img src={item.Images && item.Images[0]} className="img-fluid mimage" />

                                                </Col>
                                                <Col xs="8" className="no-pa-left">
                                                    <div className="title-imageMobile">
                                                        <h3>{item.Name}</h3>
                                                        <div className="type d-flex">
                                                            <h6>{item.Manufacturer}, {item.attributes && item.attributes['zemlja-porekla']}</h6>
                                                            <h6>{item.Package}</h6>
                                                        </div>

                                                    </div>
                                                    <div className="wrapper ">
                                                        <div className="d-flex quantity">
                                                            <h2>{item.cartQuantity}</h2>
                                                        </div>
                                                        <div className="price">
                                                            <h4>{this.props.shopCore.formatPrice(item.price * item.cartQuantity, 2)} RSD</h4>

                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })
                            }





                            <div className="ukupno-price">
                                <Row>

                                    <Col xs="6">
                                        <h4>Iznos bez PDV-a</h4>
                                    </Col>
                                    <Col xs="6" className="d-flex justify-content-end">
                                        <h4><span>{this.state.order ? this.props.shopCore.formatPrice(this.state.order.itemsTotal, 2) : 0} RSD</span></h4>
                                    </Col>
                                    <Col xs="12">
                                        <div className="hr"></div>
                                    </Col>
                                    <Col xs="6">
                                        <h6>Iznos PDV-a</h6>
                                    </Col>
                                    <Col xs="6" className="d-flex justify-content-end">
                                        <h6>{this.state.order ? this.state.order.tax : 0} RSD</h6>
                                    </Col>
                                    <Col xs="12">
                                        <div className="hr"></div>
                                    </Col>
                                    <Col xs="6">
                                        <h6>Dostava</h6>
                                    </Col>
                                    <Col xs="6" className="d-flex justify-content-end">
                                        <h6>{this.state.order && this.state.order.DeliveryMethod ? this.state.order.DeliveryMethod.price : 0} RSD</h6>
                                    </Col>
                                    <Col xs="12">
                                        <div className="hr"></div>
                                    </Col>
                                    <Col xs="6">
                                        <h5>UKUPAN IZNOS</h5>
                                    </Col>
                                    <Col xs="6" className="d-flex justify-content-end">
                                        <h5><span>{this.state.order ? this.props.shopCore.formatPrice(this.state.order.total, 2) : 0} RSD</span></h5>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {/**cd */}





                    </div>

                </Container>

                <div className="whiteFooter">
                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

export default OrderDone
