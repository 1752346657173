import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg'
import ReactPaginate from 'react-paginate';
import Sidebar from "react-sidebar";

import Navigacija from '../containers/navbar.js';
import Banner from '../containers/banner';
import News from '../components/newsHomeComponent.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import DetailFooter from '../containers/detailFooter.js';

import searchIcon from '../images/searchIcon.svg';

import vine1 from '../images/vina/vine1.png'
import vine2 from '../images/vina/vine2.png'
import vine3 from '../images/vina/vine3.png'
import vine4 from '../images/vina/vine4.png'
import expand from '../images/category/expand.svg';

import arrowDown from '../images/category/arrowDown.svg';

import RightNavbar from '../containers/slidenav.js';

import slika1 from '../images/newsHome/slika1.png';
import slika2 from '../images/newsHome/slika2.png';
import slika3 from '../images/newsHome/slika3.png';


import {
    Col,
    Row,
    Label,
    Input,
    Button,
    FormGroup,
    Container,


} from 'reactstrap';

const lat = "44.8134022";
const lang = "20.4547105";


const news = [
    {
        _id: "1",
        image: slika1,
        date: "23",
        month: "Apr",
        title: "Lorem Ipsum dolor sit amet",
        fullDate: "24.05.2019. god.",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.",
        link: "/blogDetail"

    },
    {
        _id: "2",
        image: slika2,
        date: "23",
        month: "Apr",
        title: "Lorem Ipsum dolor sit amet",
        fullDate: "24.05.2019. god.",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.",
        link: "/blogDetail"

    },
    {
        _id: "3",
        image: slika3,
        date: "23",
        month: "Apr",
        title: "Lorem Ipsum dolor sit amet",
        fullDate: "24.05.2019. god.",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.",
        link: "/blogDetail"

    }
];
export default class Category extends React.Component {
    constructor(props) {
        super(props);
        this.updateFilter = this.updateFilter.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.suggestions = this.suggestions.bind(this);

        this.state = {
            sidebarOpen: false,
            articles: [],
            total: 0,
            page: 1,
            attributes: [],
            news: [],
            filters: {},
            sort: 0,
            search: ''

        }
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.fetchAttributes();

        if (this.props.categories.length)
            this.fetchProducts();

        this.props.shopCore.fetchNews({ count: 6 }, (data) => {
            //alert(JSON.stringify(data));
            this.setState({ news: data });
        })

    }


    sortArticles(sort) {
        this.setState({
            sort: sort
        }, () => {
            this.fetchProducts();
        });


    }

    fetchAttributes() {
        let path = this.props[0].location.pathname;
        let brokenPath = path.split("&");
        let category = brokenPath[0].replace("/category", "");

        this.props.shopCore.fetchCategoryAttributes({
            category: category,
        }, (data) => {
            console.log(data);
            this.setState({
                attributes: data,

            });
        });

    }

    fetchProducts() {
        console.log(this.state.page);
        let path = this.props[0].location.pathname;
        let brokenPath = path.split("&");
        let category = brokenPath[0].replace("/category", "");
        let filters = {};

        for (let i = 1; i < brokenPath.length; i++) {
            let brokenFilter = brokenPath[i].split("=");
            filters[brokenFilter[0]] = brokenFilter[1];
        }

        this.setState({
            filters: filters
        })


        let sort = this.state.sort;


        let search, page, priceMinMax;

        page = filters.page;
        search = filters.search;
        priceMinMax = filters.priceMinMax;
        delete filters.page;
        delete filters.search;
        delete filters.priceMinMax;


        this.props.shopCore.fetchProducts({
            category: category,
            skip: page ? (parseInt(page) - 1) * 9 : 0,
            priceMinMax: priceMinMax,
            search: search,
            attributes: filters,
            count: 9,
            sort: sort
        }, (data) => {
            window.scrollTo(0, 0);
            this.setState({
                articles: data.products,
                total: data.total

            });
        });

        let breadcrumb = this.generateBreadcrumb(this.props.categories, category, 0);


        if (!page) {
            this.setState({
                page: 0
            }, () => {
                this.forceUpdate();
            })
        }


        if (this.state.page != page && page) {
            this.setState({
                page: parseInt(page)
            })
        }

        this.props.updateBreadcrumb(!breadcrumb ? [] : breadcrumb);

    }

    updateFilter(name, val) {
        let path = this.props[0].location.pathname;
        let brokenPath = path.split("&");

        let filters = {};

        for (let i = 1; i < brokenPath.length; i++) {
            let brokenFilter = brokenPath[i].split("=");
            filters[brokenFilter[0]] = brokenFilter[1];
        }

        filters[name] = val;

        let link = brokenPath[0];

        if (link == "/category") {
            link = "/category/";
        }
        for (var key in filters) {
            if (filters.hasOwnProperty(key)) {
                link += "&" + key + "=" + filters[key];
            }
        }

        if (!filters.page) {
            this.setState({
                page: parseInt(filters.page) - 1
            }, () => {
                this.forceUpdate();
            })
        }


        this.props[0].history.push(link)

    }

    componentDidUpdate(prevProps) {
        if (!prevProps.categories.length && this.props.categories.length) {
            this.fetchProducts();

        }
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.fetchProducts();
            this.fetchAttributes();

            this.props.shopCore.fetchNews({ count: 6 }, (data) => {
                //alert(JSON.stringify(data));
                this.setState({ news: data });
            })

        }
    }


    generateBreadcrumb(items, breadcrumb, level) {
        if (breadcrumb[0] === '/')
            breadcrumb = breadcrumb.substr(1)
        let broken = breadcrumb.split("/");
        let arr = [];
        if (level >= broken.length) {
            return [];
        }

        for (let i = 0; i < items.length; i++) {
            if (broken[level] == items[i].Alias) {

                arr.push({
                    _id: items[i]._id,
                    name: items[i].Name,
                    link: "/category" + items[i].Breadcrumb
                });

                if (items[i].subcategories && items[i].subcategories.length) {
                    arr = arr.concat(this.generateBreadcrumb(items[i].subcategories, breadcrumb, level + 1));
                }

                return arr;
            }
        }
        return [];

    }



    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }
    componentWillMount() {
        window.scroll(0, 0);
    }

    addToCart(item) {


        this.props.shopCore.addToCart({ _id: item._id, quantity: parseInt(item.MinOrder) }, (data) => {
            if (data.successful) {
                this.props.showInfoMessage('Proizvod je uspešno dodat u korpu!');
                this.props.shopCore.cartInfo('cartInfo');
            } else {
                this.props.showInfoMessage('Proizvod trenutno nije na stanju', true);

            }

        });



    }

    suggestions() {
        if (this.state.search.length) {
            this.props.shopCore.suggestions({ search: this.state.search }, (data) => {
                console.log(data)
                this.setState({
                    imageErrors: {},
                    articles: data.products
                })

            });
        }
    }


    render() {

        const settingsNav = {
            sidebar: <RightNavbar {...this.props} filters={this.state.filters} updateFilter={this.updateFilter} attributes={this.state.attributes}></RightNavbar>,
            open: this.state.sidebarOpen,
            onSetOpen: this.onSetSidebarOpen,
            pullRight: true,
            rootClassName: "root",
            sidebarClassName: "slideNav",
            touchHandleWidth: 25,



        }

        const settings = {

            previousLabel: '',
            previousLinkClassName: 'no-ma',
            nextLabel: '',
            breakLabel: '...',
            breakClassName: 'break-me',
            pageCount: this.state.total / 9,
            marginPagesDisplayed: this.state.width <= 767 ? 2 : 3,
            pageRangeDisplayed: this.state.width <= 767 ? 1 : 2,
            onPageChange: (page) => { this.updateFilter('page', page.selected + 1) },
            pageClassName: 'all',
            pageLinkClassName: 'allLink',
            containerClassName: 'pagination',
            subContainerClassName: 'pages pagination',
            activeClassName: 'active',
            forcePage: this.state.page - 1

        }



        console.log(this.props.breadcrumb);

        return (
            <div>
                {console.log(this.props)}
                <Navigacija {...this.props}></Navigacija>

                <Banner {...this.props}></Banner>

                <Container>
                    <Row>
                        <Col xl="3" lg="4" md="4" className="d-none d-md-block d-lg-block" >

                            <RightNavbar {...this.props} filters={this.state.filters} updateFilter={this.updateFilter} attributes={this.state.attributes}></RightNavbar>

                        </Col>
                        <Col xl="9" lg="8" md="8" xs="12">
                            <Row>
                                <Col xl="6" xs="6">
                                    <div className="breadcrumbs">
                                        <h3><Link to="/">Početna</Link> / <span>{this.props.breadcrumb && this.props.breadcrumb[0] && this.props.breadcrumb[0].name}</span></h3>
                                    </div>
                                </Col>
                                <Col xl="6" xs="6">
                                    <div className="d-md-none d-lg-none rightMenu">

                                        <Sidebar {...settingsNav} >
                                            <button className="buttonSlideMenu" onClick={() => this.onSetSidebarOpen(true)}>
                                                <Isvg src={expand} /></button>

                                        </Sidebar>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="padding">
                                <Col xl="6" lg="6" md="6" xs="6">
                                    <div className="d-flex justify-content-start left">
                                        <h4>{this.props.breadcrumb && this.props.breadcrumb[0] && this.props.breadcrumb[0].name}</h4>
                                    </div>
                                </Col>
                                <Col xl="6" lg="6" md="6" xs="6">

                                    <div className="d-flex justify-content-end right">

                                        <div className="category-search-container">
                                            <div className="search">

                                                <input type="search" onKeyDown={(e) => {
                                                }} onFocus={this.articles}
                                                    value={this.state.search}
                                                    onChange={(e) => { this.setState({ search: e.target.value }, () => this.suggestions()) }}
                                                    className={this.state.articles.length ? 'no-bottom-radius' : ''} placeholder="Unesite pojam za pretragu...">
                                                </input>
                                                <Isvg onClick={() => {
                                                    this.props[0].history.push(`/category/&search=${this.state.search}`)
                                                }} src={searchIcon}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </Col>
                            </Row>
                            <Row >
                                {
                                    this.state.articles.slice(0, 12).map((item, idx) => {
                                        return (
                                            <Col xl="4" lg="4" md="4" xs="6">
                                                <div className="box_category">
                                                    <Link to={`/product/${item._id}/${item.Alias}`}><img src={item.Images && item.Images.length && item.Images[0]} className="img-fluid" /></Link>
                                                    {item.mpcDiscount || item.vpcDiscount ?
                                                        <div className="action">
                                                            <p>-{item.mpcDiscount ? Math.round(item.mpcDiscount) + '%' : Math.round(item.vpcDiscount) + '%'}</p>
                                                        </div>

                                                        : null}


                                                    <div className="content">
                                                        <Link to={`/product/${item._id}/${item.Alias}`}><h2>{item.Name}</h2></Link>
                                                        <h6>{item.Manufacturer}, {item.attributes && item.attributes['zemlja-porekla']}</h6>
                                                        <h3>{this.props.shopCore.formatPrice(item.price)} RSD</h3>
                                                        <Button onClick={() => this.addToCart(item)}>U KORPU</Button>
                                                    </div>
                                                </div>
                                            </Col>

                                        )
                                    })
                                }


                            </Row>
                            {this.state.articles.length < 6 ?

                                null

                                :

                                <ReactPaginate {...settings} />}
                        </Col>
                    </Row>
                </Container>


                <div className="news_wrapper">
                    <h2>VESTI I OBAVEŠTENJA</h2>
                    <News items={this.state.news}></News>
                    <div className="d-flex justify-content-center">
                        <Link to="#"><Button>ARHIVA VESTI</Button></Link>
                    </div>
                </div>


                <div className="googleMap">
                    <Map {...this.props} lat={lat} lang={lang}></Map>
                </div>
                <DetailFooter></DetailFooter>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}
