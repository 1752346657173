import React, { Component } from 'react';
import slide1 from '../images/slide1.png'
import slide2 from '../images/slide2.jpg'
import searchIcon from '../images/searchIcon.svg';
import Isvg from 'react-inlinesvg'
import { Link } from 'react-router-dom';

import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Row,
    Col,
    Container
} from 'reactstrap';

const items = [
    {
        src: slide1
    }


];

class CarouselSlider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            suggestions: [

            ],
            activeIndex: 0,
            search: '',

        }
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.settings.Slides.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.settings.Slides.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

    }
    suggestions() {
        if (this.state.search.length) {
            this.props.shopCore.suggestions({ search: this.state.search }, (data) => {
                this.setState({
                    imageErrors: {},
                    suggestions: data.products
                })

            });
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                suggestions: []
            })
        }

    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);


    }


    render() {
        const { activeIndex } = this.state;

        const slides = this.props.settings.Slides.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item}
                >
                    <img src={item} />
                    <div className="overlay"></div>

                </CarouselItem>
            );
        });

        return (
            <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
                className="carousel-fade"
                ride="carousel"
            >
                <CarouselIndicators className={this.state.suggestions.length ? 'hide-indicators' : ''} items={this.props.settings.Slides} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
            </Carousel>

        );
    }
}


export default CarouselSlider;
