
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

class Check2 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <>
                {this.props.error ? <div onClick={() => this.props.onChange(!this.props.value)} className="input-wrap">
                    <div className={!this.props.value ? "requiredCheckbox checkbox" : "checkbox"}></div>
                    <span>{this.props.label}</span>
                </div> : <div onClick={() => this.props.onChange(!this.props.value)} className="input-wrap">
                        <div className={this.props.value ? "checkbox checked" : "checkbox"}></div>
                        <span>{this.props.label}</span>
                    </div>}


            </>
        );
    }
}

export default Check2;