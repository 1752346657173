import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';
import showResults from '../showResults.js';
import { renderTextField, renderTextareaField, renderCheckField, renderCheckField2, email, required } from '../../fields/fields.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap'


const registerForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, checkedValue } = props;

    return (
        <form onSubmit={handleSubmit} >
            <Field
                name="User.EMail"
                type="email"
                component={renderTextField}
                requiredField
                placeholder="E-mail"
                validate={[required, email]}
            ></Field>

            <Field
                name="password"
                type="password"
                component={renderTextField}
                requiredField
                placeholder="Lozinka"
                validate={[required]}
            ></Field>

            <Field
                name="repeat_password"
                type="password"
                component={renderTextField}
                requiredField
                placeholder="Ponovite lozinku"
                validate={[required]}
            ></Field>

            <Field
                name="BillingAddress.FirstName"
                type="text"
                component={renderTextField}
                requiredField
                placeholder="Ime"
                validate={[required]}
            ></Field>


            <Field
                name="BillingAddress.LastName"
                type="text"
                component={renderTextField}
                requiredField
                placeholder="Prezime"
                validate={[required]}
            ></Field>

            <Field
                name="BillingAddress.Company"
                type="text"
                placeholder="Firma"
                component={renderTextField}
            ></Field>

            <Field
                name="BillingAddress.City"
                type="text"
                component={renderTextField}
                requiredField
                placeholder="Grad"
                validate={[required]}
            ></Field>

            <Field
                name="BillingAddress.Street"
                type="text"
                component={renderTextField}
                requiredField
                placeholder="Ulica i broj"
                validate={[required]}
            ></Field>

            <Field
                name="BillingAddress.Zipcode"
                type="text"
                component={renderTextField}
                requiredField
                placeholder="Poštanski broj"
                validate={[required]}
            ></Field>

            <Field
                name="BillingAddress.Phone"
                type="text"
                component={renderTextField}
                requiredField
                placeholder="Telefon"
                validate={[required]}
            ></Field>
            <Field
                name="termsOfService"
                type="text"
                component={renderCheckField2}
                validate={[required]}
                label={(<>Slažem se sa <Link to = "/">Uslovima Korištenja</Link></>)
                
            }
            ></Field>
            <Field
                name="SubscribeToNewsletter"
                type="text"
                component={renderCheckField}
                label={('Preplatite se na newsletter')}
                checkedValue={checkedValue}

            ></Field>

            <Button><span>REGISTRUJ SE</span></Button>


        </form>

    )
}

export default reduxForm({
    form: 'registerForm',  // a unique identifier for this form

})(registerForm)
