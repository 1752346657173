import React from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navigacija from '../containers/navbar.js';
import Map from '../containers/map.js';
import DetailFooter from '../containers/detailFooter.js';
import Footer from '../containers/footer.js';

import about from '../images/about/about.png';
import trust from '../images/about/trust.svg';
import vine from '../images/about/vine.svg';
import location from '../images/about/location.svg';

import {
    Container,
    Row,
    Col

} from 'reactstrap';

const lat = "44.8134022";
const lang = "20.4547105";

export default class Terms extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let data = this.props.pages && this.props.pages[this.props[0].match.params.alias] ?  this.props.pages[this.props[0].match.params.alias] : {};

        return (
            <>
                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> / <span></span></h3>
                    </div>
                    <div className="heading">
                        <h1>{data.title}</h1>
                        <div dangerouslySetInnerHTML={{__html: data.content}}>
                        </div>
                    </div>
                </Container>



               {/* <div className="googleMap">
                    <Map {...this.props} lat = {lat} lang={lang}></Map>
        </div>*/}
        
                <DetailFooter></DetailFooter>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}