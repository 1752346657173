import React from 'react';
import { Link } from 'react-router-dom';

import {
    Col,
    Container,
    Row,
    Button,
    FormGroup,
    Input
} from 'reactstrap';

export default class Newsletter extends React.Component {
    constructor(props) {
        super(props);
        this.subscribeToNewsletter = this.subscribeToNewsletter.bind(this);
        this.state = {

        };
    }


    subscribeToNewsletter(val) {
        this.props.shopCore.updateUserData({
            SubscribeToNewsletter: val
        }, (data) => {
            if (data.successful) {
                this.props.setUserData(data.user);
            }
        });

    }
    render() {
        return (
            <>
                <Col xl="9" md="8" lg="9" xs="12">

                    <div className="personalData">
                        <div className="personalBox">
                            <h1>NEWSLETTER</h1>
                            <p>Da li želite da budete u toku? <br></br>
                                Prijavite se na naš newsletter kako bi primali najnovije aktuelnosti i promotivne ponude.</p>
                            <br />
                            <p>Primićete e-mail na kojem morate da potvrdite pretplatu na Newsletter.</p>
                            <br />
                            <p>Napomena: Dostava e-mail poruke može potrajati nekoliko minuta.<br />
                                Proverite da li je poruka e-pošte premeštena u spam folder .<br />
                                Ako ne primite ovu e-poruku, pokušajte ponovo ili nas kontaktirajte direktno.</p>

                            <p>Možete se odjaviti sa našeg Newsletter na ovoj stranici u bilo kom trenutku.</p>

                            <Row>
                                {this.props.uData && !this.props.uData.SubscribeToNewsletter ?

                                    <Col xl="3" md="4" lg="3" xs="6" className="no-pa"><Button onClick={() => this.subscribeToNewsletter(true)}>PRIJAVI SE</Button></Col>
                                    :
                                    <Col xl="3" md="3" lg="3" xs="6" className="no-pa"><Button onClick={() => this.subscribeToNewsletter(false)} className="btn2">ODJAVI SE</Button></Col>
                                }
                            </Row>

                        </div>
                    </div>
                </Col>

            </>
        );
    }
}