import React from 'react';
import { Link } from 'react-router-dom';
import Navigacija from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';
import ForgotPasswordForma from '../../components/forms/forgotPasswordForma.js'

import {
    Container,
    Col,
    Row,
    FormGroup,
    Input,
    Button
} from 'reactstrap';


export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.sendEmail = this.sendEmail.bind(this);

        this.state = {

        }
    }

    sendEmail(data) {
        console.log(data);
        this.props.shopCore.userForgetPassword(data);
        this.setState({
            _done: true
        })
    }


    render() {
        return (
            <>
                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> / <span>Zaboravljena lozinka</span></h3>
                    </div>
                </Container>
                <Container>
                    <Col xl="6" lg="6" md="6" xs="12" className="mx-auto">
                        <div className="forgot">
                            <div className="title">
                                <h4>Zaboravljena lozinka</h4>
                            </div>
                            <div className="forgotBox">
                                <div className="content">
                                    <h6>Zaboravili ste lozinu?</h6>

                                    <p>Poslaćemo vam link na vaš e-mail na kojem možete da upišete novu lozinku.</p>
                                    <ForgotPasswordForma onSubmit={this.sendEmail}></ForgotPasswordForma>
                                    {
                                        this.state._done ?
                                        <div className="alert alert-success" role="alert">
                                            Proverite vaš e-mail.
                                        </div>
                                        : null

                                    }
                                </div>

                            </div>
                        </div>
                    </Col>
                </Container>
                <div className="whiteFooter">
                    <Footer {...this.props}></Footer>
                </div>
            </>
        );
    }
}