import React from 'react';
import { Link } from 'react-router-dom';
import Map from '../containers/map.js';

import Slider from '../components/slider.js';


import Navigacija from '../containers/navbar.js';
import Footer from '../containers/footer.js';
import DetailFooter from '../containers/detailFooter.js';



import slika1 from '../images/shopLocation/slika1.png';
import slika2 from '../images/shopLocation/slika2.png';
import slika3 from '../images/shopLocation/slika3.png';
import slika4 from '../images/shopLocation/slika4.png';
import slika5 from '../images/shopLocation/slika5.jpg';


import slider1 from '../images/shopLocation/slider1.png'
import slider2 from '../images/shopLocation/slider2.png'
import slider3 from '../images/shopLocation/slider3.png'
import slider4 from '../images/shopLocation/slider4.png'


import location0_1 from '../images/location0/img1.jpg'
import location0_2 from '../images/location0/img2.jpg'
import location0_3 from '../images/location0/img3.jpg'
import location0_4 from '../images/location0/img4.jpg'

import location1_1 from '../images/location1/img1.jpg'
import location1_2 from '../images/location1/img2.jpg'
import location1_3 from '../images/location1/img3.jpg'

import location2_1 from '../images/location2/img1.jpg'
import location2_2 from '../images/location2/img2.jpg'
import location2_3 from '../images/location2/img3.jpg'
import location2_4 from '../images/location2/img4.jpg'

import location3_1 from '../images/location3/img1.jpg'
import location3_2 from '../images/location3/img2.jpg'
import location3_3 from '../images/location3/img3.jpg'
import location3_4 from '../images/location3/img4.jpg'



import {
    Col,
    Container,
    Row,
    Button,

} from 'reactstrap';

const slide1 = [
    {
        _id: 1,
        image: location0_1
    },
    {
        _id: 1,
        image: location0_2
    },
    {
        _id: 1,
        image: location0_3
    },
    {
        _id: 1,
        image: location0_4
    },
    {
        _id: 1,
        image: location0_1
    }
];

const slide2 = [
    {
        _id: 1,
        image: location1_1
    },
    {
        _id: 1,
        image: location1_2
    },
    {
        _id: 1,
        image: location1_3
    },
    {
        _id: 1,
        image: location1_1
    },
    {
        _id: 1,
        image: location1_2
    }
];


const slide3 = [
    {
        _id: 1,
        image: location2_1
    },
    {
        _id: 1,
        image: location2_2
    },
    {
        _id: 1,
        image: location2_3
    },
    {
        _id: 1,
        image: location2_4
    },
    {
        _id: 1,
        image: location2_1
    }
];

const slide4 = [
    {
        _id: 1,
        image: location3_1
    },
    {
        _id: 2,
        image: location3_2
    },
    {
        _id: 3,
        image: location3_3
    },
    {
        _id: 4,
        image: location3_4
    },
    {
        _id: 3,
        image: location3_3
    }
]


export default class ShopLocations extends React.Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        window.scroll(0, 0);
    }

    render() {
        const lat = "44.8134022";
        const lang = "20.4547105";

        const lat1 = "44.79429";
        const lang1 = "20.478152";

        const lat2 = "44.800414";
        const lang2 = "20.487719";

        const lat3 = "44.813553";
        const lang3 = "20.45446";

        const lat4 = "44.8518124";
        const lang4 = "20.3989707";

        const lat5 = "44.81390637142585";
        const lang5 = "20.45931448171974";

        return (
            <>
                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> /  <span>Prodavnice</span></h3>
                    </div>

                    <div className="headingText">
                        <h1>CERPROMET PRODAVNICE VINA I RAKIJA</h1>
                        <h6>Najveći izbor domaćih vina i rakija u Beogradu</h6>
                        <p>Maloprodajne objekete "Cerpromet", možete posetiti na pet dobro poznatih adresa. Specifičnost ove dugogodišnje porodične firme
                            je u tome, što u svakom lokalu imate direktnu komunikaciju sa ljubaznim prodavcima koji će Vas rado uslužiti i preporučiti Vam
                            nešto iz naše velike ponude pića. Naši trgovci su tu da Vam daju sve potrebne informacije u vezi sa našim poslovanjem kao i
                            asortimanom koji Vam nudimo. Uz mogućnosti plaćanja gotovinom, naši kupci mogu platiti čekovima kao i platnim karticama.</p>

                        <p>Dobrodošli su nam svi zainteresovani i potencijalni kupci koji prave proslave rođendana, svadbi, slava, krštenja, koktele i slično da
                            nas posete, jer pored svega što im je potrebno od pića da bi ugostili svoje goste, nudimo i sopstveni prevoz robe do željene
                            lokacije.</p>
                    </div>
                </Container>

                <Container fluid className="no-pa">
                    <Row noGutters>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="contactDetail">
                                <img src={slika5} className="img-fluid bgc" />
                                <div className="overlay">
                                    <div className="content left">
                                        <h1>Prizrenska 6</h1>
                                        <p>telefon: 011/765-7708 </p>

                                        <p> radno vreme: <br />
                                            radnim danima: 8:00 - 21:00 <br />
                                            subotom: 08:00 - 16:00</p>

                                        <p>Kako doći:
                                            <br />
                                            Linijama GSP-a: 15, 21, 22, 23, 26, 27, 29, 31, 52, 53, 56, 68, 75, 94</p>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="shopLocations">
                                <Map {...this.props} lat1={lat5} lang1={lang5}></Map>
                            </div>
                        </Col>

                        <Slider items={slide4}></Slider>

                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="shopLocations">
                                <Map {...this.props} lat={lat} lang={lang}></Map>
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="contactDetail">
                                <img src={slika1} className="img-fluid bgc" />
                                <div className="overlay">
                                    <div className="content">
                                        <h1>Gavrila Principa 9</h1>
                                        <p>telefon: 011/292-8506</p>

                                        <p> radno vreme:<br />
                                            radnim danima: 8:00 - 21:00<br />
                                            subotom: 08:00 - 16:00</p>

                                        <p>Kako doći:<br />
                                            Linijama GSP-a: 2, 7, 9, 11, 52, 53, 56</p>
                                    </div>

                                </div>
                            </div>
                        </Col>


                        <Slider items={slide1}></Slider>


                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="contactDetail">
                                <img src={slika2} className="img-fluid bgc" />
                                <div className="overlay">
                                    <div className="content left">
                                        <h1>Maksima Gorkog 34</h1>
                                        <p>telefon: 011/292-8361 </p>

                                        <p> radno vreme: <br />
                                            radnim danima: 8:00 - 21:00 <br />
                                            subotom: 08:00 - 16:00</p>

                                        <p>Kako doći:
                                            <br />
                                            Linijama GSP-a: 25, 26, 46, 55</p>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="shopLocations">
                                <Map {...this.props} lat1={lat1} lang1={lang1}></Map>
                            </div>
                        </Col>


                        <Slider items={slide1}></Slider>



                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="shopLocations">
                                <Map {...this.props} lat2={lat2} lang2={lang2}></Map>
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="contactDetail">
                                <img src={slika3} className="img-fluid bgc" />
                                <div className="overlay">
                                    <div className="content">
                                        <h1>Jedrenska 1</h1>
                                        <p>telefon: 011/2402-967 </p>

                                        <p>

                                            radno vreme:<br />
                                            radnim danima: 07:00 - 20:00<br />
                                            subotom: 08:00 - 16:00</p>

                                        <p>Kako doći: <br />
                                            Linijama GSP-a: 5, 6, 7, 14</p>
                                    </div>
                                </div>

                            </div>
                        </Col>

                        <Slider items={slide2}></Slider>

                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="contactDetail">
                                <img src={slika4} className="img-fluid bgc" />
                                <div className="overlay">
                                    <div className="content left">
                                        <h1>Kapetan Mišina 30</h1>
                                        <p>telefon: 011/2628-364 </p>

                                        <p> radno vreme:  <br />
                                            radnim danima: 7:00 - 20:00 <br />
                                            subotom: 08:00 - 16:00</p>

                                        <p>Kako doći:<br />
                                            Linijama GSP-a: 2,5,10,24,26,79</p>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="shopLocations">
                                <Map {...this.props} lat3={lat3} lang3={lang3}></Map>
                            </div>
                        </Col>

                        <Slider items={slide3}></Slider>

                        {/*<Col xl="6" lg="6" md="6" xs="12">
                            <div className="shopLocations">
                                <Map {...this.props} lat4 = {lat4} lang4 = {lang4}></Map>
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="contactDetail">
                                <img src={slika3} className="img-fluid bgc" />
                                <div className="overlay">
                                    <div className="content">
                                        <h1>Dunavska 9, Veleprodaja</h1>
                                        <p>telefoni: 011/2928-360</p>

                                        <p>

                                            cerpromet1@mts.rs<br />
                                            radno vreme:<br />
                                            radnim danima: 07:00 - 15:00<br />
                                            subotom: 09:00 - 14:00</p>

                                        <p>Kako doći: <br />
                                            Linijama GSP-a: 5, 6, 7, 14</p>
                                    </div>

                                </div>

                            </div>
                        </Col>
                        <Slider items={slide1}></Slider>
*/
                        }

                    </Row>
                </Container>

                <DetailFooter></DetailFooter>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}