import React from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navigacija from '../containers/navbar.js';
import Map from '../containers/map.js';
import DetailFooter from '../containers/detailFooter.js';
import Footer from '../containers/footer.js';


import delivery from '../images/delivery/delivery.png';
import truck from '../images/delivery/truck.svg';
import clock from '../images/delivery/clock.svg';
import contact from '../images/delivery/contact.svg';

import { Container, Col, Row } from 'reactstrap';

export default class Delivery extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <>
                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> / <span>Dostava</span></h3>
                    </div>
                    <div className="heading">
                        <h1>DOSTAVA</h1>
                        <p>
                            Vršimo besplatnu isporuku vina, rakija i žestokih pića na kućnu adresu za sve porudžbine na teritoriji cele drzave koje prelaze iznos od 20.000 dinara.
                            Za porudžbine na teritoriji grada Beograda čiji iznos iznosi manje od 5.000 dinara, naplaćuje se dostava u iznosu od 400 dinara.
                            Isporuka artikala se vrsi do vrata kupca (osim u zgradama bez lifta gde kupac pruzima robu ispred stanbene zgrade).
                            Kompletan asortiman mozete pronaci u nasim maloprodadnijm objektima na vise lokacija u Beogradu.
                        </p>
                    </div>
                </Container>
                <div className="deliveryContent">

                    <div className="divSlika">
                        <img src={delivery} className="img-fluid slika" />
                        <div className="overlay"></div>
                    </div>
                    <div className="proba">
                        <Container>
                            <Row>
                                <Col xl="4" lg="4" md="4" xs="12">
                                    <div className="box">

                                        <div className="content">
                                            <Isvg src={truck} />
                                            <h4>KAKO I GDE DOSTAVLJAMO</h4>
                                            <p>Vršimo isporuke na adrese svih
                                                pravnih i fizičkih lica na teritoriji
                                                Srbije preko kurirskih službi, a na
                                                teret naručioca. Isporuka robe za
                                                pravna lica je u roku od 24h nakon
                                                uplate na naš račun (145-10-42 Expo
                                                bank ).</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="4" xs="12">
                                    <div className="box">

                                        <div className="content" xs="12">
                                            <Isvg src={clock} />
                                            <h4>VREME DOSTAVE</h4>
                                            <p>Roba naručena do 12h svakog radnog
                                                dana, biće dostavljena u roku od 1
                                                (jednog) radnog dana na adresu
                                                kupca. Isporuke se vrše radnim
                                                danom od 07h – 15h, subotom od 08h
                                                – 12h.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="4" xs="12">
                                    <div className="box">

                                        <div className="content">
                                            <Isvg src={contact} />
                                            <h4>KONTAK ZA NARUČIVANJE</h4>
                                            <p>Kontakt telefon:</p>
                                            <p> +381 11 2928 506,</p>
                                            <p>Radnim danom od 08-21 h, subotom od 08-16 h</p>
                                            <p>064/8229-091</p>
                                            <p>Radnim danom od 08 - 15 h, subotom od 08 - 14 h</p>
                                            <p>Mail za porudžbine:</p>
                                            <p>veleprodaja@cerpromet.com</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>




                <DetailFooter></DetailFooter>
                <Footer {...this.props}></Footer>
            </>
        )
    }
}