import React from 'react';
import Isvg from 'react-inlinesvg'
import { Link } from 'react-router-dom'

import Navigacija from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import piceIcon from '../images/piceIcon.svg'
import UniversalSlider from '../components/universalSlider'
import DetailFooter from '../containers/detailFooter.js';



import background from '../images/background.png';
import vine from '../images/detail/vine.png';
import small from '../images/detail/small.png';
import arrow from '../images/detail/arrowDown.svg'
import heart from '../images/detail/heart.svg';

import vine1 from '../images/vina/vine1.png'
import vine2 from '../images/vina/vine2.png'
import vine3 from '../images/vina/vine3.png'
import vine4 from '../images/vina/vine4.png'
import DocumentMeta from 'react-document-meta';

import {
    Col,
    Row,

    Label,
    Input,
    Button,
    FormGroup,
    Container
} from 'reactstrap';
const lat = "44.8134022";
const lang = "20.4547105";
const striptags = require('striptags');

const pice = [
    {
        _id: 1,
        image: vine1,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/1',
        action: <div className="action"><p>-20%</p></div>
    },
    {
        _id: 3,
        image: vine2,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/2'
    }, {
        _id: 3,
        image: vine3,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/3'
    },
    {
        _id: 4,
        image: vine4,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/4'
    },
    {
        _id: 5,
        image: vine1,
        title: 'Confronto Collefrisio',
        subtitle: 'Collefrisio, Italija',
        price: '2100 RSD',
        link: '/5'
    }

];

export default class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.addToCart = this.addToCart.bind(this);
        this.addToWishlist = this.addToWishlist.bind(this);

        this.state = {
            quantity: 0,
            product: {},
            menu: {},
            activeIdx: 0,
            packages: [],
            similar: [],
            popular: []
        };
    }




    componentDidMount() {

        if (window.fbq){
            window.fbq('track', 'ViewContent');
        }


        this.props.shopCore.fetchPopularProducts((data) => {
            this.setState({ popular: data });
        })

        window.scrollTo(0, 0);
        console.log(this.props[0].match.params.id);
        this.props.shopCore.fetchProduct({ _id: this.props[0].match.params.id }, (data) => {
            console.log(data);
            this.setState({
                product: data,
                quantity: data.MinOrder
            });

            this.props.shopCore.fetchProducts({
                category: data.Breadcrumb,
                skip: 0,
                search: null,
                attributes: {},
                count: 20,
                sort: 0
            }, (res) => {
                if (res.products.length < 4 && res.products.length !== 0){
                    let idx = 0;
                    while(res.products.length < 4){
                        res.products.push(res.products[idx]);
                        idx++;
                    }                    
                }
                this.setState({
                    similar: res.products,
    
                });
            });



        });

        this.props.shopCore.fetchProductPackages({ Alias: this.props[0].match.params.alias }, (data) => {
            console.log(data);
            this.setState({
                packages: data,
            });


        });


    }


    generateBreadcrumb(items, breadcrumb, level) {
        if (breadcrumb[0] === '/')
            breadcrumb = breadcrumb.substr(1)
        let broken = breadcrumb.split("/");
        let arr = [];
        if (level >= broken.length) {
            return [];
        }

        for (let i = 0; i < items.length; i++) {
            if (broken[level] == items[i].Alias) {

                arr.push({
                    _id: items[i]._id,
                    name: items[i].Name,
                    link: "/kategorija" + items[i].Breadcrumb
                });

                if (items[i].subcategories && items[i].subcategories.length) {
                    arr = arr.concat(this.generateBreadcrumb(items[i].subcategories, breadcrumb, level + 1));
                }

                return arr;
            }
        }
        return [];

    }

    componentDidUpdate(prevProps) {

        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.setState({
                product: {},
                packages: []
            }, () => {

                if (window.fbq){
                    window.fbq('track', 'ViewContent');
                }
                this.props.shopCore.fetchProduct({ _id: this.props[0].match.params.id }, (data) => {
                    this.setState({
                        product: data,
                        quantity: data.MinOrder,
                    });

                    this.props.shopCore.fetchProducts({
                        category: data.Breadcrumb,
                        skip: 0,
                        search: null,
                        attributes: {},
                        count: 20,
                        sort: 0
                    }, (res) => {
                        if (res.products.length < 4 && res.products.length !== 0){
                            let idx = 0;
                            while(res.products.length < 4){
                                res.products.push(res.products[idx]);
                                idx++;
                            }                    
                        }
                        this.setState({
                            similar: res.products,
            
                        });
                    });
            



                });

                this.props.shopCore.fetchProductPackages({ Alias: this.props[0].match.params.alias }, (data) => {
                    console.log(data);
                    this.setState({
                        packages: data,
                    });
                });


                
                //this.props.socketIOClient.emit('fetchProduct', { _id: this.props[0].match.params.id });
            });
        }

        if (!this.props.breadcrumb.length && this.props.categories.length && this.state.product && this.state.product._id && this.state.product.Breadcrumb && !this.state._updatingBreadcrumb) {
            let breadcrumb = this.generateBreadcrumb(this.props.categories, this.state.product.Breadcrumb, 0);
            this.setState({
                _updatingBreadcrumb: true
            })
            this.props.updateBreadcrumb(!breadcrumb ? [] : breadcrumb);
        }

    }

    addToCart() {

        if (this.state.product.SelectedAttributes) {

            let selectedAttributes = this.state.product.SelectedAttributes;
            let attributes = this.state.product.attributes;

            for (let i = 0; i < attributes.length; i++) {
                if (!selectedAttributes[attributes[i].Alias]) {
                    this.props.showInfoMessage('Selektujte sve atribute', true);
                    return;
                }
            }


            this.props.shopCore.addToCart({ _id: this.props[0].match.params.id, quantity: parseInt(this.state.quantity), selectedAttributes: this.state.product.SelectedAttributes }, (data) => {
                if (data.successful) {
                    this.props.showInfoMessage('Proizvod je uspešno dodat u korpu!');
                    this.props.shopCore.cartInfo('cartInfo');
                } else {
                    this.props.showInfoMessage('Proizvod trenutno nije na stanju', true);

                }

            });

        } else {
            this.props.shopCore.addToCart({ _id: this.props[0].match.params.id, quantity: parseInt(this.state.quantity) }, (data) => {
                if (data.successful) {
                    this.props.showInfoMessage('Proizvod je uspešno dodat u korpu!');
                    this.props.shopCore.cartInfo('cartInfo');
                } else {
                    this.props.showInfoMessage('Proizvod trenutno nije na stanju', true);

                }

            });

        }

    }

    addToWishlist() {
        if (!this.props.uData) {
            this.props[0].history.push('/login');
            return;
        }

        this.props.shopCore.addToWishlist({ _id: this.props[0].match.params.id }, (data) => {
            this.props.showInfoMessage('Proizvod je uspešno dodat u listu omiljenih!');

        });
    }


    componentWillMount() {
        window.scroll(0, 0);
    }

    IncrementItem = () => {
        this.setState({ quantity: this.state.quantity + 1 });
    }
    DecreaseItem = () => {
        if (this.state.quantity - 1 < this.state.product.MinOrder) {
            return;
        }
        this.setState({ quantity: this.state.quantity - 1 });
    }



    render() {
        let product = this.state.product;
        const meta = {
            title:  product.Name ? (product.Name + ' | Cerpromet - Prodaja i distribucija vina, rakija i alkoholnih pića, maloprodaja i veleprodaja vina, distributeri vina, vinoteka u Beogradu, domaće vinarije, manastirska vina i rakije, domaća vina i rakije') : 'Cerpromet - Prodaja i distribucija vina, rakija i alkoholnih pića, maloprodaja i veleprodaja vina, distributeri vina, vinoteka u Beogradu, domaće vinarije, manastirska vina i rakije, domaća vina i rakije',
            description: striptags(product.ShortDescription),
            meta: {
                charset: 'utf-8',
                property: {
                    'og:url': `https://cerpromet.com/product/${product._id}/${product.Alias}`,
                    'og:title': product.Name ? (product.Name + ' | Cerpromet - Prodaja i distribucija vina, rakija i alkoholnih pića, maloprodaja i veleprodaja vina, distributeri vina, vinoteka u Beogradu, domaće vinarije, manastirska vina i rakije, domaća vina i rakije') : 'Cerpromet - Prodaja i distribucija vina, rakija i alkoholnih pića, maloprodaja i veleprodaja vina, distributeri vina, vinoteka u Beogradu, domaće vinarije, manastirska vina i rakije, domaća vina i rakije',
                    'og:image': product.Images && product.Images[0],
                    'og:description':  striptags(product.ShortDescription),

                }
            }
        };

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>
                <DocumentMeta {...meta} />
                <div className="detailWrapper">
                    <Container>
                        <div className="breadcrumbs">
                            <h3><Link to="/">Početna</Link>   / {this.props.breadcrumb && this.props.breadcrumb[0] && this.props.breadcrumb[0].name}  / <span>{product.Name}</span></h3>
                        </div>

                        <div className="detaill">
                            <Row>
                                <Col xl="6" lg="6" md="6">
                                    <Row className="">
                                        <Col xl="4" lg="4" md="3" className="d-md-none d-lg-block d-none">
                                            {
                                                product.Images && product.Images.map((item, idx) => {
                                                    return (
                                                        <img onClick={() => this.setState({ activeIdx: idx })} key={idx} src={item} className="img-fluid smallImg" />

                                                    )
                                                })
                                            }
                                        </Col>
                                        <Col xl="8" lg="8" md="12"><div className="relative-wrap"><img src={product.Images && product.Images[this.state.activeIdx]} className="preview-image" />
                                            {product.mpcDiscount || product.vpcDiscount ?
                                                <div className="discount">
                                                    <p>-{product.mpcDiscount ? product.mpcDiscount + '%' : product.vpcDiscount + '%'}</p>
                                                </div>

                                                : null}
                                        </div>


                                        </Col>

                                    </Row>
                                </Col>
                                <Col xl="6" lg="6" md="6">
                                    <div className="text">
                                        <h5>{this.props.breadcrumb && this.props.breadcrumb[0] && this.props.breadcrumb[0].name}</h5>
                                        <h1>{product.Name}</h1>

                                        <div className="d-flex status">
                                            <h2>{product.Package}</h2>
                                            {product.StockLevel > 0 ?
                                                <div className="green"></div>
                                                :
                                                <div className="red"></div>
                                            }
                                            {product.StockLevel > 0 ? <h6>Na stanju</h6> : <h6>Nema na stanju</h6>}
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: product.ShortDescription }}></div>
                                        <div className="hr"></div>


                                    </div>
                                    <div className="prise">
                                        <Row>
                                            {product.mpcDiscount || product.vpcDiscount ?
                                                <Col xl="4" xs="6" lg="6" md="6">
                                                    <h2>{product.mpc ? this.props.shopCore.formatPrice(product.mpc * (1.2), 0) : this.props.shopCore.formatPrice(product.vpc * (1.2), 0)} RSD</h2>
                                                </Col>
                                                : null
                                            }
                                            <Col xl="8" xs="6" lg="6" md="6">
                                                <h3>{this.props.shopCore.formatPrice(product.price)} RSD</h3>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="inputFields">
                                        <Row>
                                            <Col xl="4" lg="4" md="4" xs="6">
                                                <div className="d-flex justify-content-center quantity">
                                                    <label>Količina</label>
                                                    <button className="btnn dec" onClick={this.DecreaseItem}>-</button>
                                                    <h2>{this.state.quantity}</h2>
                                                    <button className="btnn inc" onClick={this.IncrementItem}>+</button>
                                                </div>

                                            </Col>
                                            {this.state.packages.length ? <Col xl="8" lg="8" md="8" xs="6">
                                                <FormGroup>
                                                    <Label for="exampleSelect">Ambalaža</Label>
                                                    <div className="placeholder d-none d-lg-none d-xl-block">Izaberi Ambalažu</div>

                                                    <Input type="select" name="select" id="exampleSelect" onChange={(e) => {
                                                        this.props[0].history.push(`/product/${e.target.value}/${this.props[0].match.params.alias}`)
                                                    }}>
                                                        {
                                                            this.state.packages.map((item, idx) => {
                                                                return (
                                                                    <option selected={item._id == this.props[0].match.params.id ? true: false} value={item._id}>{item.Package}</option>
                                                                )
                                                            })
                                                        }
                                                    </Input>
                                                    <Isvg src={arrow} />
                                                </FormGroup>

                                            </Col>

                                                : null
                                            }
                                        </Row>
                                    </div>

                                    <div className="d-flex favorite" onClick={this.addToWishlist}>
                                        <Isvg src={heart} />
                                        <h6>Dodaj u listu omiljenih</h6>
                                    </div>

                                    <div className="d-flex justify-content-center button">
                                        <button onClick={this.addToCart} className="btnKorpa">U KORPU</button>
                                    </div>

                                </Col>

                                <Col xl="2"></Col>
                                <Col xl="10" lg="12" md="12">
                                    <div className="header d-flex">
                                        <h4 className={this.state.menu.item1 ? "" : "active"} onClick={() => {
                                            let menu = this.state.menu;
                                            menu.item1 = !menu.item1;
                                            this.setState({ menu });

                                        }}>O proizvodu</h4>

                                        <h4 className={this.state.menu.item1 ? "active" : ""} onClick={() => {
                                            let menu = this.state.menu;
                                            menu.item1 = !menu.item1;
                                            this.setState({ menu });

                                        }}>Specifikacije</h4>
                                    </div>
                                    <div className="description">
                                        {!this.state.menu.item1 ?
                                            <div className="about" dangerouslySetInnerHTML={{ __html: product.Description }}></div>
                                            : null}


                                        {this.state.menu.item1 ?
                                            <div className="spec">
                                                <Row>
                                                    <Col xl="6" lg="6" md="6" xs="12">
                                                        <Row>
                                                            <Col xl="7" lg="7" md="6" xs="6" className="font-weight-medium underline">
                                                                {
                                                                    product.attrs && product.attrs.map((attr, idx) => {
                                                                        return (
                                                                            <p>{attr.name}</p>

                                                                        )
                                                                    })
                                                                }
                                                            </Col>
                                                            <Col xl="4" lg="4" md="6" xs="6" className="underline">
                                                                {
                                                                    product.attrs && product.attrs.map((attr, idx) => {
                                                                        return (
                                                                            <p>{attr.value}</p>

                                                                        )
                                                                    })
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/*
                                                <Col xl="6" lg="6" md="6" xs="12">
                                                <Row>
                                                    <Col xl="7" lg="7" md="6" xs="6" className="font-weight-medium">
                                                    <p>Zemlja poreka</p>
                                                    <p>Boja</p>
                                                    <p>Sorta</p>
                                                    <p>Berba</p>
                                                    <p>Lorem ipsum</p>
                                                    <p>Lorem ipsum</p>
                                                    <p>Lorem ipsum</p>
                                                    <p>Lorem ipsum</p>
                                                    <p>Lorem ipsum</p>
                                                    <p>Lorem ipsum</p>
                                                </Col>
                                                <Col xl="4" lg="4" md="6" xs="6">
                                                <p>Srbija</p>
                                                    <p>Roze vino</p>
                                                    <p>Sorta</p>
                                                    <p>Cabernet Franc</p>
                                                    <p>2016</p>
                                                    <p>Lorem ipsum</p>
                                                    <p>Lorem ipsum</p>
                                                    <p>Lorem ipsum</p>
                                                    <p>Lorem ipsum</p>
                                                    <p>Lorem ipsum</p>
                                                </Col>
                                                </Row>
                                                </Col>
                                                */}
                                                </Row>
                                            </div>
                                            : null}

                                    </div>
                                </Col>
                            </Row>

                        </div>

                    </Container>
                </div>

                <div className="universalSlider">
                    <Container>
                        <Row>
                            <Col xl="12" lg="12" md="12">
                                <div className="heading">
                                    <Isvg src={piceIcon} />
                                    <h6>SLIČNI PROIZVODI</h6>
                                    <p>Proizvodi iz iste kategorije</p>
                                </div>
                            </Col>
                            <UniversalSlider shopCore={this.props.shopCore} showInfoMessage={this.props.showInfoMessage} items={this.state.similar}></UniversalSlider>
                        </Row>
                    </Container>
                </div>

                <div className="universalSliderWrapper">
                    <img src={background} className="bg" />
                    <div className="universalSlider">
                        <Container>
                            <Row>
                                <Col xl="12" lg="12" md="12">
                                    <div className="heading">
                                        <Isvg src={piceIcon} />
                                        <h6>PREPORUČENI PROIZVODI</h6>
                                        <p>Pića koja preporucujemo</p>
                                    </div>
                                </Col>
                                <UniversalSlider shopCore={this.props.shopCore} showInfoMessage={this.props.showInfoMessage} items={this.state.popular}></UniversalSlider>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="googleMap">
                    <Map {...this.props} lat={lat} lang={lang}></Map>
                </div>

                <DetailFooter></DetailFooter>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}