import React from 'react';
import { Link } from 'react-router-dom';

import Navigacija from '../containers/navbar.js';
import Map from '../containers/map.js';
import DetailFooter from '../containers/detailFooter.js';
import Footer from '../containers/footer.js';
import RecommendationsItem from '../containers/recommendationsItem.js';
import Recomm from '../images/recommendations.png';

import {
    Col,
    Row,
    Button,
    Container
} from 'reactstrap';

const lat = "44.8134022";
const lang = "20.4547105";

const RecommJsom = [
    {
        _id: 1,
        image: Recomm,
        title: 'Žuti Muskat San Martino',
        subtitle: 'Kvalitetno polusuvo belo vino KPZ',
        manufacturer: 'Vinogradi San Martino, Labin, Istra, Hrvatska',
        year: '2016.',
        sort: 'Žuti Muskat 100%',
        alk: '12 %vol.',
        price: '959 RSD',
        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        link: "/test"
    },
    {
        _id: 2,
        image: Recomm,
        title: 'Žuti Muskat San Martino',
        subtitle: 'Kvalitetno polusuvo belo vino KPZ',
        manufacturer: 'Vinogradi San Martino, Labin, Istra, Hrvatska',
        year: '2016.',
        sort: 'Žuti Muskat 100%',
        alk: '12 %vol.',
        price: '959 RSD',
        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        link: "/test"
    },
    {
        _id: 3,
        image: Recomm,
        title: 'Žuti Muskat San Martino',
        subtitle: 'Kvalitetno polusuvo belo vino KPZ',
        manufacturer: 'Vinogradi San Martino, Labin, Istra, Hrvatska',
        year: '2016.',
        sort: 'Žuti Muskat 100%',
        alk: '12 %vol.',
        price: '959 RSD',
        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        link: "/test"
    }
];

export default class Recommendations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }
    componentWillMount() {
        window.scroll(0, 0);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.shopCore.fetchPopularProducts((data) => {
            this.setState({ items: data });
        })

    }
    componentDidUpdate(prevProps) {
        if (!prevProps.uData && this.props.uData) {
            this.props.shopCore.fetchPopularProducts((data) => {
                this.setState({ items: data });
            })
        }
    }


    render() {
        return (
            <>
                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> / <span>Preporuke</span></h3>
                    </div>
                </Container>
                <Container>
                    <div className="recommendations">
                        <h1>PREPORUKE</h1>
                        <p>Cerpromet Beograd je prva privatna firma koja se bavi distribucijom i prodajom pića. Osnovana je 1990. godine i od tada do danas
                        priznata je kao jedno od najozbiljnijih i najcenjenijih preduzeća u ovoj oblasti. Izabrani smo od velikih trgovinskih lanaca: Delhaize
                        Serbia, Idea i Mercator S kao dobavljači za srpske proizvođače vina i rakija.
                            <br></br>
                            <br></br>

                            Više decenija iskustva u prodaji alkoholnih i bezalkoholnih napitaka pretočeno je u ovu porodičnu firmu. Cerpromet je
                            prepoznatljiv po tome što potrošačima i saradnicima nudi impozantnu paletu pića počevši od voda, sokova, kafe, likera, votki,
                        konjaka, vinjaka, rakija, vina, viskija… </p>
                    </div>


                    <Row>

                        {this.state.items.map((item) => {
                            return (
                                    <RecommendationsItem {...item} ></RecommendationsItem>
                            )
                        })}

                    </Row>


                </Container>
                <div className="googleMap">
                    <Map {...this.props} lat= {lat} lang={lang}></Map>
                </div>
                <DetailFooter></DetailFooter>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}
