import socketIO from 'socket.io-client';


export class Core {
    constructor(props) {
        this.socket = socketIO('https://socket.cerpromet.com');

        this.socket.on('cartInfo', props.cartInfoCallback);
        this.socket.on('reconnect', props.reconnectCallback)

    }


    formatPrice(price, dec=2){
        /*price = parseFloat(price).toFixed(dec);
        price = price.replace(".", ",");

        return price;*/

        let dec_point = ',';
        let thousands_sep = '.';
    
        var parts = parseFloat(price).toFixed(2).split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);
    
        return parts.join(dec_point);
    }

    fetchHomeProducts(callback) {
        this.socket.emit('fetchHomeProducts', {});

        this.socket.once('fetchHomeProducts', callback);
    }

    confirmEmail(data, callback) {
        this.socket.emit('confirmEmail', data);
        this.socket.once('confirmEmail', callback);
    }


    fetchTransaction(data, callback) {
        this.socket.emit('fetchTransaction', data);
        this.socket.once('fetchTransaction', callback);
    }

    fetchOrder(data, callback) {
        this.socket.emit('fetchOrder', data);
        this.socket.once('fetchOrder', callback);
    }


    fetchCategories(callback) {
        this.socket.emit('fetchCategories', {});

        this.socket.once('fetchCategories', callback);
    }

    fetchSettings(callback) {
        this.socket.emit('fetchSettings', {});

        this.socket.once('fetchSettings', callback);
    }

    fetchProducts(query, callback) {
        this.socket.emit('fetchProducts', query);

        this.socket.once('fetchProducts', callback);
    }
    fetchPopularProducts(callback) {
        this.socket.emit('fetchPopularProducts', {});

        this.socket.once('fetchPopularProducts', callback);
    }


    fetchProduct(query, callback) {
        this.socket.emit('fetchProduct', query);

        this.socket.once('fetchProduct', callback);

    }
    fetchProductPackages(query, callback) {
        this.socket.emit('fetchProductPackages', query);

        this.socket.once('fetchProductPackages', callback);

    }


    userRegister(data, callback) {
        this.socket.emit('userRegister', data);

        this.socket.once('userRegister', callback);

    }

    userLogin(data, callback) {
        this.socket.emit('userLogin', data);

        this.socket.once('userLogin', callback);
    }

    userVerify(data, callback) {
        this.socket.emit('userVerify', data);

        this.socket.once('userVerify', callback);

    }

    updateUserData(data, callback) {
        this.socket.emit('updateUserData', data);

        this.socket.once('updateUserData', callback);
    }

    addToCart(data, callback) {
        if (window.fbq){
            window.fbq('track', 'AddToCart');
        }


        this.socket.emit('addToCart', data);

        this.socket.once('addToCart', callback);
    }

    cartInfo() {
        this.socket.emit('cartInfo', {});
    }

    fetchCurrentOrder(callback){
        this.socket.emit('fetchCurrentOrder', {});

        this.socket.once('fetchCurrentOrder', callback);

    }

    updateCurrentOrder(data, callback){
        this.socket.emit('updateCurrentOrder', data);

        this.socket.once('updateCurrentOrder', callback);
    }

    executeOrder(callback){
        this.socket.emit('executeOrder', {});

        this.socket.once('executeOrder', callback);
    }

    suggestions(data, callback){
        this.socket.emit('suggestions', data);

        this.socket.once('suggestions', callback);
    }

    addToWishlist(data, callback){
        this.socket.emit('addToWishlist', data);

        this.socket.once('addToWishlist', callback);
    }

    wishlist(callback){
        this.socket.emit('wishlist', {});

        this.socket.once('wishlist', callback);

    }

    fetchNews(data, callback){
        this.socket.emit('fetchNews', data);

        this.socket.once('fetchNews', callback);

    }
    fetchNewsItem(data, callback){
        this.socket.emit('fetchNewsItem', data);

        this.socket.once('fetchNewsItem', callback);

    }

    fetchSlides(callback){
        this.socket.emit('fetchSlides', {});

        this.socket.once('fetchSlides', callback);

    }


    fetchBanners(callback){
        this.socket.emit('fetchBanners', {});

        this.socket.once('fetchBanners', callback);

    }


    fetchPages(callback){
        this.socket.emit('fetchPages', {});

        this.socket.once('fetchPages', callback);

    }


    fetchGallery(callback){
        this.socket.emit('fetchGallery', {});

        this.socket.once('fetchGallery', callback);

    }

    fetchSpecialOfferProducts(callback){
        this.socket.emit('fetchSpecialOfferProducts', {});

        this.socket.once('fetchSpecialOfferProducts', callback);

    }

    contact(data, callback){
        this.socket.emit('contact', data);

        this.socket.once('contact', callback);
    }

    userForgetPassword(data){
        this.socket.emit('userForgetPassword', data);

    }

    userResetPassword(data, callback){
        this.socket.emit('userResetPassword', data);

        this.socket.once('userResetPassword', callback);
    }

    removeFromWishlist(data, callback){
        this.socket.emit('removeFromWishlist', data);

        this.socket.once('removeFromWishlist', callback);
    }

    fetchOrders(callback){
        this.socket.emit('fetchOrders', {});

        this.socket.once('fetchOrders', callback);
    }

    removeCartItem(data, callback){
        this.socket.emit('removeCartItem', data);

        this.socket.once('removeCartItem', callback);
    }
    
    searchClients(data, callback){
        this.socket.emit('searchClients', data);

        this.socket.once('searchClients', callback);
    }

    fetchProductAttributes(data, callback){
        this.socket.emit('fetchProductAttributes', data);

        this.socket.once('fetchProductAttributes', callback);
    }


    fetchCategoryAttributes(data, callback){
        this.socket.emit('fetchCategoryAttributes', data);

        this.socket.once('fetchCategoryAttributes', callback);
    }

    userChangePassword(data, callback){
        this.socket.emit('userChangePassword', data);

        this.socket.once('userChangePassword', callback);
    }
}