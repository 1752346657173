import React from 'react';
import {Link} from 'react-router-dom';
import {
    Col,
    Row,
 
    Button,
    Containers
} from 'reactstrap';
import moment from 'moment';

export default class NewsHomeItem extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>

                <div className={this.props.newsHome ? "whitebox newsBox" : "newsBox" && this.props.blogDetail ? "newsDetail newsBox" : "newsBox"}>


                    <Row className={this.props.blogDetail ? "noGutters" : ""}>

                        <Col xl={this.props.newsHome ? "4" : "12"} lg={this.props.newsHome ? "5" : "12"} md={this.props.newsHome ? "5" : "12"} xs="12">
                            <div className="image_box">
                               
                            <Link to={`/blog/${this.props._id}/${this.props.Alias}`}> <img src={this.props.Image} className="img-fluid" /></Link>
                                <div className="content">
                                    <h4>{moment.unix(this.props.time).format('DD')}</h4>
                                    <h3>{moment.unix(this.props.time).format('MMM')}</h3>
                                </div>
                            </div>
                        </Col>
                        <Col xl={this.props.newsHome ? "8" : "12"} lg={this.props.newsHome ? "7" : "12"} md={this.props.newsHome ? "7" : "12"} className={this.props.newsHome ? "white" : ""} xs="12">
                            <div className="head">
                                <h1>{this.props.Title}</h1>
                                <h6>{moment.unix(this.props.time).format('DD.MM.YYYY.')} god.</h6>
                                <p>{this.props.ShortDescription}</p>
                                {this.props.newsHome ?
                                    <Link to={`/blog/${this.props._id}/${this.props.Alias}`}><Button className="btndetail">DETALJNIJE</Button></Link>
                                    : null}
                            </div>
                        </Col>
                    </Row>


                </div>

            </>
        );
    }
}