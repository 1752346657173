import React, { Component } from 'react';
import ArticleItem from './articleItem'
import article1 from '../images/articles/article1.png'
import article2 from '../images/articles/article2.png'
import article3 from '../images/articles/article3.png'
import article4 from '../images/articles/article4.png'
import article5 from '../images/articles/article5.png'
import article6 from '../images/articles/article6.png'

import Slider from "react-slick";



const article = [
    {
        _id: 1,
        image: article1,
        title: 'Naziv artikla 1',
        price: '2100 RSD',
        link: '/1'
    },
    {
        _id: 3,
        image: article2,
        title: 'Naziv artikla 2',
        price: '2100 RSD',
        link: '/2'
    }, {
        _id: 3,
        image: article3,
        title: 'Naziv artikla 3',
        price: '2100 RSD',
        link: '/3'
    },
    {
        _id: 4,
        image: article4,
        title: 'Naziv artikla 4',
        price: '2100 RSD',
        link: '/4'
    },
    {
        _id: 5,
        image: article5,
        title: 'Naziv artikla 5',
        price: '2100 RSD',
        link: '/5'
    },
    {
        _id: 6,
        image: article6,
        title: 'Naziv artikla 6',
        price: '2100 RSD',
        link: '/6'
    },
    {
        _id: 7,
        image: article1,
        title: 'Naziv artikla 7',
        price: '2100 RSD',
        link: '/7'
    },
];


class Article extends React.Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {

        }

    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            slidesToShow: this.state.width <= 767 ? 2 : 4 && this.state.width <= 1024 ? 3 : 6
        };
        return (

            <div className="test">
                <Slider {...settings}>
                    
                    {this.props.items.map((item, idx) => {
                        return (
                            <div key={item._id}>

                                    <ArticleItem shopCore={this.props.shopCore} showInfoMessage={this.props.showInfoMessage} {...item}></ArticleItem>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        );
    }
}


export default Article;