import React, { Component } from 'react';
import { Router, Route, Link, Switch } from "react-router-dom";
import Home from './views/home.js';
import Blog from './views/blog.js';

import About from './views/about.js';
import Detail from './views/detail.js';
import Contact from './views/contact.js';
import Delivery from './views/delivery.js';
import Category from './views/category.js';
import GoogleMap from './googleMapScript.js';
import BlogDetail from './views/blogDetail.js';
import ShopLocations from './views/shopsLocations.js';
import Recommendations from './views/recommendations.js';

import Login from './views/forms/login.js';
import ForgotPassword from './views/forms/forgotPasswordForm.js';
import NewPassword from './views/forms/newPassword.js';
import Profile from './views/forms/profile.js';


import Cart from './views/shopingCart/cart.js';
import DeliveryAddress from './views/shopingCart/deliveryAddress.js';
import CartLogin from './views/shopingCart/cartLogin.js';
import OverviewCart from './views/shopingCart/overviewCart.js';
import './App.css';
import {Core} from  './core';
import createHistory from 'history/createBrowserHistory'
import ConfirmMail from './views/forms/confirmMail.js';
import Terms from './views/terms.js';
import PaymentPreview from './views/shopingCart/paymentPreview';
import OrderDone from './views/shopingCart/orderDone';

const history = createHistory();


class App extends React.Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    window.googleMapsCallback = this.callback;

    this.updateBreadcrumb = this.updateBreadcrumb.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.showInfoMessage = this.showInfoMessage.bind(this);
    this.hideInfoMessage = this.hideInfoMessage.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.allowCookies = this.allowCookies.bind(this);


    this.shopCore = new Core({
      cartInfoCallback: (data) => {
        this.setState({
          cartCount: data.count,

        })

      },
      reconnectCallback: () => {
        this.fetchData()
      }
    });

    this.state = {
      categories: [],
      breadcrumb: [],
      banners: [],
      gallery: [],
      specialOffer: [],
      cartCount: 0,
      pages: {},
      settings: {
        Slides: []
      },
      infoMessages: {

      }

    };
  }
  callback(){
    this.setState({ mapinit: true});
  }

  showInfoMessage(text, error) {
    console.log(this.state.infoMessages);
    let messages = this.state.infoMessages;
    let idx = Date.now().toString();
    messages[idx] = {
      idx: idx,
      message: text,
      error: error
    };

    this.setState({
      infoMessages: messages
    }, () => {
      this.forceUpdate();
      setTimeout(() => {
        this.hideInfoMessage(idx);
      }, 3000);
    });



  }

  hideInfoMessage(idx) {
    let messages = this.state.infoMessages;
    if (!messages[idx])
      return;
    messages[idx].animate = true;
    this.setState({
      infoMessages: messages
    }, () => {
      setTimeout(() => {


        let messages = this.state.infoMessages;
        delete messages[idx];
        this.setState({
          infoMessages: messages
        })
      }, 1000);
    })
  }

  fetchData(){
      
    this.shopCore.fetchSettings((data) => {
      this.setState({ settings: data });
    });

    this.shopCore.fetchPages((data) => {
      this.setState({ pages: data });
    });



    if (localStorage.uData) {
      let uData = JSON.parse(localStorage.uData);

      if (uData && uData.User && uData.User.EMail && uData.User.pk){
        this.shopCore.userVerify({ email: uData.User.EMail, pk: uData.User.pk }, (data) => {
          console.log(data);
          if (data.successful) {
            this.setUserData(data.user);
            this.shopCore.cartInfo();

          }

          
          this.shopCore.fetchCategories((data) => {
            this.setState({ categories: data });
          });

          this.shopCore.fetchGallery((data) => {
            this.setState({ gallery: data });
          });


          this.shopCore.fetchBanners((data) => {
            this.setState({ banners: data });
          });


          this.shopCore.fetchSpecialOfferProducts((data) => {
            this.setState({ specialOffer: data });
          });



        });
      }else{
        this.shopCore.fetchCategories((data) => {
          this.setState({ categories: data });
        });
    
        this.shopCore.fetchGallery((data) => {
          this.setState({ gallery: data });
        });
    
    
        this.shopCore.fetchBanners((data) => {
          this.setState({ banners: data });
        });
    
    
        this.shopCore.fetchSpecialOfferProducts((data) => {
          this.setState({ specialOffer: data });
        });
  
      }
    }else{
      this.shopCore.fetchCategories((data) => {

        this.setState({ categories: data });
      });
  
      this.shopCore.fetchGallery((data) => {
        this.setState({ gallery: data });
      });
  
  
      this.shopCore.fetchBanners((data) => {
        this.setState({ banners: data });
      });
  
  
      this.shopCore.fetchSpecialOfferProducts((data) => {
        this.setState({ specialOffer: data });
      });
    }

  }

  componentDidMount() {

    setTimeout(() => {
      window.prerenderReady = true;
    }, 5000);

    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }


    document.addEventListener('mousedown', this.handleClickOutside);

    this.fetchData();

    console.log(history);

    history.listen((location, action) => {
      console.log(location);
      
      window.scrollTo(0, 0);


      console.log(location);
      if (typeof window.gtag === 'function') {

        /*window.ga('set', 'page', location.pathname + location.search);
        window.ga('send', 'pageview');*/

        window.gtag('config', 'UA-75168242-2', {
          'page_title': window.document.title,
          'page_location': window.location.href,
          'page_path': location.pathname
      });

      }

    })

  }


  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        articleModal: null
      })
    }

  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  updateBreadcrumb(bcrumb) {
    this.setState({
      breadcrumb: bcrumb
    });
  }

  setUserData(data) {
    this.setState({
      uData: data
    });

    localStorage.uData = JSON.stringify(data);

  }

  allowCookies() {
    localStorage.allowCookies = true;
    this.setState({
      cookies: true
    });
  }

  render() {
    return (
      <div>
         <GoogleMap API_KEY="AIzaSyAaSt58UDZVRmMYe52a3cCPfJaoCaHUJqY" />
        <Router history={history}>
          <Switch>
            <Route exact path="/" render={(...renderProps) => (<Home {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...this.state}></Home>)} />
            <Route exact path="/payment/:status/:oid" render={(...renderProps) => (<PaymentPreview {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...this.state}></PaymentPreview>)} />
            <Route exact path="/order-success/:oid" render={(...renderProps) => (<OrderDone {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...this.state}></OrderDone>)} />

            <Route exact path="/product/:id/:alias" render={(...renderProps) => (<Detail {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...this.state}></Detail>)} />
            <Route exact path="/category/:alias" render={(...renderProps) => (<Category {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...this.state}></Category>)} />
            <Route exact path="/shopLocations" render={(...renderProps) => (<ShopLocations {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...this.state}></ShopLocations>)} />
            <Route exact path="/blog" render={(...renderProps) => (<Blog {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...this.state}></Blog>)} />
            <Route exact path="/blog/:id/:alias" render={(...renderProps) => (<BlogDetail {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...this.state}></BlogDetail>)}/>
            <Route exact path="/contact" render={(...renderProps) => (<Contact {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...this.state}></Contact>)}/>
            <Route exact path="/recommendations" render={(...renderProps) => (<Recommendations {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}></Recommendations>)}/>
            <Route exact path="/delivery" render={(...renderProps) => (<Delivery {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}></Delivery>)}/>
            <Route exact path="/about" render={(...renderProps) => (<About {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}></About>)}/>
            <Route exact path="/page/:alias" render={(...renderProps) => (<Terms {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}></Terms>)}/>

            <Route exact path="/login" render={(...renderProps) => (<Login {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}/>)}/>
            <Route exact path="/register" render={(...renderProps) => (<Login {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}/>)}/>
            <Route exact path="/confirm-email/:uid/:code" render={(...renderProps) => (<ConfirmMail {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}/>)}/>

            <Route exact path="/forgotPassword" render={(...renderProps) => (<ForgotPassword {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state} />)}/>
            <Route exact path="/newPassword" render={(...renderProps) => (<NewPassword {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}/>)}/>
            <Route exact path="/profile" render={(...renderProps) => (<Profile {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}></Profile>)}/>
            <Route exact path="/profile/:item" render={(...renderProps) => (<Profile {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}></Profile>)}/>



            <Route exact path="/cart" render={(...renderProps) => (<Cart {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}/>)}/>
            <Route exact path="/cartLogin" render={(...renderProps) => (<CartLogin {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}/>)}/>
            <Route exact path="/deliveryAddress" render={(...renderProps) => (<DeliveryAddress {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}/>)}/>
            <Route exact path="/overviewCart" render={(...renderProps) => (<OverviewCart {...renderProps}  shopCore={this.shopCore} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state}/>)}/>
          </Switch>
        </Router>

        <div className="pop-up-messages">
          {
            Object.values(this.state.infoMessages).map((item, idx) => {
              console.log(item);
              return (
                <div className={item.animate ? 'hide-message ' : ''} key={idx} onClick={() => this.hideInfoMessage(item.idx)}>

                  <p className={item.error ? 'error' : ''}>{item.error ? <i className="fa fa-times" ></i> : null}{item.message}</p>
                </div>
              )
            })
          }

        </div>

          {!this.state.cookies ? <div className="cookies">
                    <p>Mi koristimo kolačiće za poboljšanje vašeg iskustva na našoj web stranici. Korištenjem ove web stranice pristajete na upotrebu kolačića.</p>
                    <button onClick={() => { this.allowCookies() }}>PRIHVATAM</button>
                    <a href='/page/privacy-policy' target="_blank" >VIŠE</a>
                </div> :
                    null
                }
      </div>
    )
  }
}

export default App;
