import React from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navigacija from '../containers/navbar.js';
import Map from '../containers/map.js';
import DetailFooter from '../containers/detailFooter.js';
import Footer from '../containers/footer.js';

import about from '../images/about/about.png';
import trust from '../images/about/trust.svg';
import vine from '../images/about/vine.svg';
import location from '../images/about/location.svg';

import {
    Container,
    Row,
    Col

} from 'reactstrap';

const lat = "44.8134022";
const lang = "20.4547105";

export default class About extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> / <span>O nama</span></h3>
                    </div>
                    <div className="heading">
                        <h1>O NAMA</h1>
                        <p>Distribucijom i prodajom vina, rakija i alkoholnih pića bavimo se
                            od dave 1990. godine. Od samog početka do danas, Cerpromet je
                            priznat kao jedno od najozbiljnijih i najcenjenijih preduzeća u ovoj
                            oblasti. Izabrani smo od velikog trgovinskog lanca Delhaize Serbia kao
                            dobavljači za srpske proizvođače vina i rakija.</p>
                    </div>
                </Container>

                <div className="deliveryContent">

                    <div className="divSlika">
                        <img src={about} className="img-fluid slika" />
                        <div className="overlay"></div>
                    </div>
                </div>
                <Container>
                    <div className="textAbout">

                        <p>Više decenija iskustva u prodaji alkoholnih i bezalkoholnih pića
                            pretočeno je u ovu porodičnu firmu. Specifičnost firme je sto stavlja
                            veliki akcenat na ponudu proizvođača rakija i vina sa teritorije naše
                            zemlje. U nashiš pet specijalizovanih maloprodajnih objektima možete
                            naći više od 600 vrsta vina, 200 vrsta rakija, 200 vrsta raznog
                            alkoholnog pica, 30 vrsta craft piva, malih i velikih proizvođača iz svih
                            krajeva naše zemlje, regiona i inostranstva. U zadnoj deceniji smo
                            prosirili delatnost na uvoz vrhunskih i kvalitetnih vina iz regiona uz
                            stručan odabir brendova.
                            Kupci su focus svih naših poslovnih odluka . Na našim rafovima uvek
                            mozete naci inovativne artikle, po odlicnim cenama, sa stalim
                            degustacijama i promocijama.</p>
                    </div>


                </Container>
                <div className="experience">
                    <Container>
                        <div className="heading">
                            <h1>34 GODINA ISKUSTVA</h1>
                            <p>Od samog početka do danas, Cerpromet je priznat kao jedno od
                                najozbiljnijih i najcenjenijih preduzeća u ovoj oblasti.</p>
                        </div>
                        <div className="box_wrapper">
                            <Row>
                                <Col xl="4" lg="4" md="4" xs="12">
                                    <div className="box">
                                        <Isvg src={trust} />
                                        <h2>POVERENJE</h2>
                                        <p></p>

                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="4" xs="12">
                                    <div className="box">
                                        <Isvg src={vine} />
                                        <h2>ASORTIMAN</h2>
                                        <p></p>

                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="4" xs="12">
                                    <div className="box">
                                        <Isvg src={location} />
                                        <h2>LOKACIJE</h2>
                                        <p></p>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>



                <div className="googleMap">
                    <Map {...this.props} lat={lat} lang={lang}></Map>
                </div>
                <DetailFooter></DetailFooter>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}