import React from 'react';


import {
    Col,
    Container,
    Row
} from 'reactstrap';
import NewsHomeItem from './newsHomeItem';



export default class NewsHomeComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const newsHome = this.props.blog;
        const blogDetail = this.props.blogDetail;
        return (
            <div>

                <Container className={this.props.blog ? "no-pa" : ""}>
                    <Row>
                        {this.props.items.map((item) =>
                            <Col key={item._id} xl={this.props.blog ? "12" : "4" && this.props.blogDetail ? "12" : "4"} lg={this.props.blog ? "12" : "4" && this.props.blogDetail ? "12" : "4"} md={this.props.blog ? "12" : "4" && this.props.blogDetail ? "12" : "4"} xs="12">

                                <NewsHomeItem newsHome={newsHome} blogDetail = {blogDetail} {...item}></NewsHomeItem>

                            </Col>
                        )}
                    </Row>
                </Container>

            </div>
        );
    }
}