import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';
import showResults from '../showResults.js';
import { renderTextField, required, email } from '../../fields/fields.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap'


const loginForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    return (
        <form onSubmit={handleSubmit}>
            <Field
                name="email"
                type="email"
                component={renderTextField}
                placeholder="E-mail"
                validate={[required]}
            ></Field>

            <Field
                name="password"
                type="password"
                component={renderTextField}
                placeholder="Lozinka"
                validate={[required]}
            ></Field>
            <Link to="/forgotPassword"><p>Zaboravili ste lozinku?</p></Link>

            <Button>PRIJAVI SE</Button>

        </form>


    )
}

export default reduxForm({
    form: 'loginForm',  // a unique identifier for this form

})(loginForm)
