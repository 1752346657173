import React from 'react';
import { Link } from 'react-router-dom';
import ChangePasswordForm from '../../components/forms/changePasswordForm';

import {
    Col,
    Container,
    Row,
    Button,
    FormGroup,
    Input
} from 'reactstrap';

export default class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.resetPassword = this.resetPassword.bind(this);

        this.state = {

        }
    }

    resetPassword(obj) {
        this.props.shopCore.userChangePassword(obj, (data) => {
            if (data.error) {
                if (data.error == 'passwords-not-match') {
                    this.setState({
                        error: 'Lozinke se ne podudaraju'
                    })
                }
            } else {
                this.props.setUserData(data.user);
                this.props[0].history.push('/profile');

            }
        })

    }


    render() {
        return (
            <>

                <Col xl="9" md="8" lg="9" xs="12">
                    <div className="personalData">
                        <div className="personalBox">
                            <h1>PROMENA LOZINKE</h1>
                            <ChangePasswordForm onSubmit={this.resetPassword}></ChangePasswordForm>
                            {
                                this.state.error ?

                                    <div className="alert alert-danger" role="alert">
                                        {this.state.error}
                                    </div>


                                    : null
                            }

                        </div>
                    </div>
                </Col>

            </>
        );
    }
}