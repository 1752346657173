import React from 'react';
import {Link} from 'react-router-dom';
import Navigacija from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';
import NewPasswordForma from '../../components/forms/newPasswordForma.js'

import {
    Container,
    Col,
    Row,
    FormGroup,
    Input,
    Button
} from 'reactstrap';


export default class NewPassword extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> / <span>Nova lozinka</span></h3>
                    </div>
                </Container>
                <Container>
                    <Col xl="6" lg="6" md="6" xs="12" className="mx-auto">
                        <div className="forgot">
                            <div className="title">
                                <h4>Nova lozinka</h4>
                            </div>
                            <div className="forgotBox">
                                <div className="content">
                                <h6>Upišite Vašu novu lozinku.</h6>
                                    <NewPasswordForma onSubmit={this.newPassword}></NewPasswordForma>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Container>
                <div className="whiteFooter">
                    <Footer {...this.props}></Footer>
                </div>
            </>
        );
    }
}