import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import {
    Row,
    Col,
    Button,
    NavItem,
    NavLink,
    Container
} from 'reactstrap';


export default class DetailFooter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>

                <div className="detailFooter">
                    <Container fluid className="no-pa">

                        <div className="contact">
                            <div className="overlay">

                                <div className="content">
                                    <h1>Kontakt</h1>
                                    <h4>Skenderbegova 27, 11000 Beograd</h4>
                                    <h4>telefon: +381 11 262 8364</h4>
                                    <h4>faks: +381 11 262 8364</h4>
                                    <h4>veleprodaja@cerpromet.com</h4>

                                    <Link to='/contact'><Button>KONTAKT</Button></Link>
                                </div>


                            </div>
                        </div>

                        <div className="stores">

                            <div className="content">
                                <h1>Prodavnice</h1>
                                <h4>Prizrenska 6</h4>
                                <h4>Gavrila Principa 9</h4>
                                <h4>Jedrenska 1</h4>
                                <h4>Kapetan Mišina 30</h4>
                                <h4>Maksima Gorkog 34</h4>
                            </div>

                        </div>
                    </Container>
                </div>
            </>
        );
    }
}