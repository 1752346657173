import React, { Component } from 'react';
import SliderItem from './sliderItem.js';

import Slider from "react-slick";



import {
    Container,
    Row,
    Col
} from 'reactstrap';



class SliderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {

        }

    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            slidesToShow: this.state.width <= 767 ? 1 : 4 && this.state.width <= 1024 ? 3 : 4
        };
        return (

            <Container fluid className="no-pa">

                <div className="imgSlider">
                    <Slider {...settings}>
                        {this.props.items.map((item, idx) => {
                            return (
                                <div key={item._id}>
                                    <SliderItem {...item}></SliderItem>
                                </div>
                            )
                        })}
                    </Slider >
                </div>

            </Container>


        );
    }
}


export default SliderComponent;