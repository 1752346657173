import React from 'react';
import { Link } from 'react-router-dom';
import NewDeliverAdressForm from '../../components/forms/newDeliveryAdressForm.js';

import {
    Col,
    Container,
    Row,
    Button,
    FormGroup,
    Input
} from 'reactstrap';

export default class PersonalData extends React.Component {
    constructor(props) {
        super(props);
        this.newDeliveryAddress = this.newDeliveryAddress.bind(this);

        this.state = {

        }
    }

    newDeliveryAddress(data) {
        console.log(data);
        let obj = {
            'FirstName': data.FirstName,
            'LastName': data.LastName,
            'Street': data.Street,
            'Zipcode': data.Zipcode,
            'City': data.City,
            'EMail': data.EMail,
        }

        let arr = this.props.uData.ShippingAddress;
        if (!arr) {
            arr = [];
        }

        arr.push(obj);

        this.props.shopCore.updateUserData({
            ShippingAddress: arr
        }, (data) => {
            if (data.successful) {
                this.props.setUserData(data.user);

                /*if (this.state.redirect) {
                    this.props[0].history.push('/nalog');
                }*/
            }
        })

    }

    render() {
        console.log(this.props.uData);
        return (
            <>
                <Col xl="9" lg="9" md="8">
                    <div className="addressTitl">
                        <h5>ADRESA ZA ISPORUKU</h5>
                    </div>

                    <Row>

                        {
                            this.props.uData && this.props.uData.ShippingAddress && this.props.uData.ShippingAddress.map((item, idx) => {
                                return (
                                    <Col xl="4" lg="4" md="12" xs="12">
                                        <div className="boxAddress">
                                            <li>{item.FirstName} {item.LastName}</li>
                                            <li>{item.Street}</li>
                                            <li>{item.Zipcode} {item.City}</li>
                                            <li>{item.EMail}</li>
                                            <Button onClick={() => {
                                                let arr = this.props.uData.ShippingAddress;
                                                arr.splice(idx, 1);
                                                this.props.shopCore.updateUserData({
                                                    ShippingAddress: arr
                                                }, (data) => {
                                                    if (data.successful) {
                                                        this.props.setUserData(data.user);

                                                        /*if (this.state.redirect) {
                                                            this.props[0].history.push('/nalog');
                                                        }*/
                                                    }
                                                })
                                            }}>Obriši</Button>
                                        </div>
                                    </Col>

                                )
                            })
                        }

                    </Row>

                    <div className="personalData">
                        <div className="newAddress">
                            <h1>NOVA ADRESA</h1>
                        </div>
                        <div className="personalBox">
                            <NewDeliverAdressForm onSubmit={this.newDeliveryAddress}></NewDeliverAdressForm>

                        </div>
                    </div>
                </Col>
            </>
        );
    }
}