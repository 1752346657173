import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';
import showResults from '../showResults.js';
import { renderTextField, required, email, passwordsMustMatch } from '../../fields/fields.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap'


const newPasswordForma = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    return (
        <form onSubmit={handleSubmit(showResults)} >

            <Field
                name="new_password"
                type="password"
                label={('Nova Lozinka')}
                component={renderTextField}
                validate={[required]}
                placeholder={'Nova lozinka'}
            />
            <Field
                name="repeat_password"
                type="password"
                label={('Ponovite Lozinku')}
                component={renderTextField}
                validate={[required]}
                placeholder={'Ponovite lozinku'}
            />

            <button className="btn"><span>SAČUVAJ</span></button>
            <div className="alert alert-danger mt-4" role="alert">
                Lozinka nije ispravna
            </div>

        </form>


    )
}

export default reduxForm({
    form: 'newPasswordForma',  // a unique identifier for this form

})(newPasswordForma)
