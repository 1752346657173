import React, { Component } from 'react';
import UniversalItem from './universalItem'
import Slider from "react-slick";






class Vine extends React.Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {

        }

    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        const UniversalSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            slidesToShow: this.state.width <= 767 ? 2 : 4 && this.state.width <= 1024 ? 3 : 4
        };
        return (
             <div className="test">
                <Slider {...UniversalSettings}>
                    {this.props.items.map((item, idx) => {
                        return (
                            <div key={item._id}>
                                    <UniversalItem shopCore={this.props.shopCore} showInfoMessage={this.props.showInfoMessage} {...item}></UniversalItem>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        );
    }
}


export default Vine;