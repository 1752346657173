import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Navigacija from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';
import ForgotPasswordForma from '../../components/forms/forgotPasswordForma.js'

import {
    Container,
    Col,
    Row,
    FormGroup,
    Input,
    Button
} from 'reactstrap';


export default class ConfirmMail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        if (this.props[0].match.params.uid && this.props[0].match.params.code && !this.state._confirmationStarted) {
            this.props.shopCore.confirmEmail({ uid: this.props[0].match.params.uid, code: this.props[0].match.params.code }, (data) => {
                if (data.successful) {
                    this.props.setUserData(data.user);
                }
            })
        }

    }


    render() {
        return (
            <>
                {this.props.uData ? <Redirect to='/profile' ></Redirect> : null}

                <Navigacija {...this.props}></Navigacija>
                <Container>
                    <div className="breadcrumbs">
                        <h3><Link to="/">Početna</Link> / <span>Potvrda e-mail adrese</span></h3>
                    </div>
                </Container>
                <Container>
                    <Col xl="6" lg="6" md="6" xs="12" className="mx-auto">
                        <div className="forgot">
                            <div className="forgotBox">
                                <div className="content">
                                    <h6>Potvrda e-mail adrese je u toku.</h6>

                                </div>

                            </div>
                        </div>
                    </Col>
                </Container>
                <div className="whiteFooter">
                    <Footer {...this.props}></Footer>
                </div>
            </>
        );
    }
}