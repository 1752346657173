import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Navigacija from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';
import LoginForm from '../../components/forms/loginForm';
import GuestLoginForm from '../../components/forms/guestLoginForm';

import{
    Col, Row, Button, Container, FormGroup, Input
} from 'reactstrap';

export class CartLogin extends Component {
    constructor(props) {
        super(props);
        this.newDeliveryAddress = this.newDeliveryAddress.bind(this);
        this.login = this.login.bind(this);

        this.state = {

        }
    }

    login(data) {
        this.props.shopCore.userLogin(data, (data) => {
            console.log(data);
            if (data.successful) {
                this.props.setUserData(data.user);
                //this.props.socketIOClient.emit('cartInfo', {});

            } else {
                if (data.error == 'email-not-verified') {
                    this.setState({
                        loginError: 'Potvrdite Vašu e-mail adresu da bi ste se prijavili!'
                    });
                } else if (data.error == 'user-not-exists') {
                    this.setState({
                        loginError: 'Korisnik nije pronadjen'
                    });
                } else if (data.error == 'wrong-credentials') {
                    this.setState({
                        loginError: 'Pogrešni podaci'
                    })
                }

            }
        });
    }


    newDeliveryAddress(data) {
        console.log(data);
        let shippingAddress = {
            'FirstName': data.FirstName,
            'LastName': data.LastName,
            'Street': data.Street,
            'Zipcode': data.Zipcode,
            'City': data.City,
            'EMail': data.EMail,
        }

        let billingAddress = {
            'FirstName': data.FirstName,
            'LastName': data.LastName,
            'Street': data.Street,
            'Zipcode': data.Zipcode,
            'City': data.City,
            'EMail': data.EMail,
            'Phone': data.Phone
        }

        this.props.shopCore.updateCurrentOrder({
            BillingAddress: billingAddress,
            ShippingAddress: shippingAddress
        }, (data) => {
            this.props[0].history.push('/overviewCart');
        });


        

    }

    render() {
        return (
            <div>
                <Navigacija {...this.props}/>
                    <Container>
                        <div className="loginguest">
                        <Row>
                            <Col xl="5" md="5" lg="5" xs="12">
                                <h1>PRIJAVITE SE</h1>
                                <LoginForm onSubmit={this.login}></LoginForm>
                                        {
                                            this.state.loginError ?
                                                <div className="alert alert-danger" role="alert">
                                                    {this.state.loginError}
                                                </div>

                                                : null
                                        }

                                    <Link to="/register" className = "mt-4">Nemate nalog? Registrujte se.</Link>
                            </Col>
                            <Col xl="5" md="5" lg="5" xs="12" className="offset-xl-1 offset-md-1 offset-lg-1">
                                <h1>NASTAVI KAO GOST</h1>
                                <GuestLoginForm onSubmit={this.newDeliveryAddress}></GuestLoginForm>
                            </Col>
                        </Row>
                        </div>
                    </Container>

                <div className="whiteFooter">
                <Footer {...this.props}/>
                </div>
            </div>
        )
    }
}

export default CartLogin
