
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';

class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
                <div className="position-relative form-group">
                    <input className={this.props.error ? 'required' : 'form-control'} type={this.props.type} onChange={this.props.onChange} value={this.props.value} placeholder={this.props.placeholder} />
                </div>
        );
    }
}

export default Text;