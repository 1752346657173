import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import showResults from '../showResults.js';
import { renderTextField, renderCheckField, email, required } from '../../fields/fields.js';

import {
    Row,
    Col,
    Button

} from 'reactstrap'


const guestLoginForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    return (
        <form onSubmit={handleSubmit} >

            <Field
                name="FirstName"
                type="text"
                component={renderTextField}
                placeholder={'Ime'}
                requiredField
                validate={[required]}
            ></Field>
            <Field
                name="LastName"
                type="text"
                component={renderTextField}
                placeholder={'Prezime'}
                requiredField
                validate={[required]}
            ></Field>

            <Field
                name="EMail"
                type="email"
                component={renderTextField}
                placeholder={'E-mail'}
                requiredField
                validate={[required, email]}
            ></Field>

            <Field
                name="Phone"
                type="text"
                component={renderTextField}
                placeholder={'Telefon'}
                requiredField
                validate={[required]}
            ></Field>
            <Field
                name="City"
                type="text"
                component={renderTextField}
                placeholder={'Grad'}
                requiredField
                validate={[required]}
            ></Field>

            <Field
                name="Zipcode"
                type="text"
                component={renderTextField}
                placeholder={'Poštanski broj'}
                requiredField
                validate={[required]}
            ></Field>

            <Field
                name="Street"
                type="text"
                component={renderTextField}
                placeholder={'Ulica i broj'}
                requiredField
                validate={[required]}
            ></Field>

            <button className="btn2">NASTAVI</button>

        </form>

    )
}

export default reduxForm({
    form: 'guestLoginForm',  // a unique identifier for this form

})(guestLoginForm)
