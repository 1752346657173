import React, { Component } from 'react';
import searchIcon from '../images/searchIcon.svg';
import Isvg from 'react-inlinesvg'
import { Link } from 'react-router-dom';
import SearchBackground from '../images/search-background.png'

import {
    Row,
    Col,
    Container
} from 'reactstrap';

class Search extends Component {
    constructor(props) {
        super(props);
        this.goToIndex = this.goToIndex.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            suggestions: [

            ],
            activeIndex: 0,
            search: '',

        }
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

    }
    suggestions() {
        if (this.state.search.length) {
            this.props.shopCore.suggestions({ search: this.state.search }, (data) => {
                this.setState({
                    imageErrors: {},
                    suggestions: data.products
                })

            });
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                suggestions: []
            })
        }

    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);


    }

    render() {
        return (
            <div className='search-background' style={{ backgroundImage: `url(${SearchBackground})` }}>
                <div className="search">
                    <input type="search" onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            this.props[0].history.push(`/category/&search=${this.state.search}`)

                        }
                    }} onFocus={this.suggestions} value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }, () => this.suggestions()) }} className={this.state.suggestions.length ? 'no-bottom-radius' : ''} placeholder="Unesite pojam za pretragu..."></input><Isvg onClick={() => {
                        this.props[0].history.push(`/category/&search=${this.state.search}`)

                    }} src={searchIcon} />
                    {this.state.suggestions.length ?

                        <div className="searchForm" ref={(node) => { this.wrapperRef = node; }}>
                            <h5>Artikli</h5>
                            <Container>
                                <Row>
                                    <div className="searchProduct">
                                        <ul>
                                            {
                                                this.state.suggestions.map((item, idx) => {
                                                    return (
                                                        <li>
                                                            <Link to={`/product/${item._id}/${item.Alias}`}>
                                                                <Row>
                                                                    <Col lg="1" md="1" xs="3">
                                                                        <img src={item.Images && item.Images[0]} className="img-fluid"></img>
                                                                    </Col>
                                                                    <Col lg="11" md="11" xs="9">
                                                                        <p>{item.Name}</p>
                                                                    </Col>
                                                                </Row>
                                                            </Link>
                                                        </li>

                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        : null}
                </div>
            </div>
        )
    }
}

export default Search;