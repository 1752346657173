import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

class SliderItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {


        return (
            <>
        
                        <img src={this.props.image} className="img-fluid" />
                      
           
            </>


        );
    }
}


export default SliderItem;