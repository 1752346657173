import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg'

import logo_icon from '../images/footer/logo_icon.svg';
import face_icon from '../images/fb.svg';
import insta_icon from '../images/instagram.svg';


import bank_logo from '../images/banca-intesa-logo.png';
import visa from '../images/visa_pos_fc.png';
import verified_by_visa from '../images/Ver-by-VBM-2c-JPG.jpg';
import master_card from '../images/mc_acc_opt_70_1x.png';
import americanExpress from '../images/AMERICAN EXPRESS BLUEBOX.png';
import secure_code from '../images/sclogo_156x83.gif';
import maestro from '../images/ms_acc_opt_70_1x.png';
import dina from '../images/DinaCard.png';

import {
    Row,
    Col,
    Button,
    NavItem,
    NavLink,
    Container
} from 'reactstrap';


export default class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>

                <div className="footer">

                    <Container>

                        <Row>
                            <Col xl="5" lg="4" xs="12" md="5" sm="12">
                                <Isvg src={logo_icon} className="logo" />
                                <h4>{this.props.settings.FooterText} </h4>

                                <h6>Pratite nas
                                    <a href="https://facebook.com/cerpromet/" target="_blank"><Isvg src={face_icon} /></a>
                                    <a href="https://www.instagram.com/cerpromet1/" target="_blank">  <Isvg src={insta_icon} /></a>

                                </h6>

                            </Col>

                            <Col xl="4" md="4" lg="4" xs="6">
                                <h5>NAVIGACIJA</h5>
                                <Row>
                                    <Col xl="6" lg="6" xs="6" md="6">
                                        <NavItem>
                                            <Link to="/">Početna</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/about">O nama</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/shopLocations">Prodavnice</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/delivery">Dostava</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/page/terms-and-conditions">Uslovi korišćenja</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/page/complaints-policy">Politika reklamacija</Link>
                                        </NavItem>

                                    </Col>

                                    <Col xl="6" lg="6" xs="6" md="6">
                                        <NavItem>
                                            <Link to="/category/pribor">Akcije</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/recommendations">Preporuke</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/blog">Blog</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/contact">Kontakt</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/page/privacy-policy">Politika privatnosti</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/page/return-policy">Povraćaj sredstava</Link>
                                        </NavItem>

                                    </Col>
                                </Row>
                            </Col>

                            <Col xl="3" lg="3" xs="6" md="3">
                                <h5>ASORTIMAN</h5>
                                <Row>
                                    <Col xl="8" xs="8" lg="8" md="8">
                                        <NavItem>
                                            <Link to="/category/vina&boja-vina=Crveno%20vino">Crvena Vina</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/category/vina&boja-vina=Belo%20vino">Bela Vina</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/category/vina&boja-vina=Rose%20vino">Roze Vina</Link>
                                        </NavItem>
                                    </Col>
                                    <Col xl="4" xs="4" lg="4" md="4">
                                        <NavItem>
                                            <Link to="/category/spiriti">Spiriti</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/category/rakije">Rakije</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/category/piva">Piva</Link>
                                        </NavItem>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row className="payment-footer">
                            <Col lg="12" className="bank-logo">
                                <a href="http://www.bancaintesabeograd.com" target="_blank"><img src={bank_logo} /></a>
                            </Col>
                            <Col lg="12" >
                                <div>
                                    <a className="sign" href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html" target="_blank"> <img src={verified_by_visa} /> </a>
                                    <a className="sign" href="http://www.mastercard.com/rs/consumer/credit-cards.html" target="_blank"> <img src={secure_code} /> </a>
                                </div>
                                <div className="signs">
                                    <img src={visa} />
                                    <img src={master_card} />
                                    <img src={maestro} />
                                    <img src={americanExpress} />
                                    <img src={dina} />

                                </div>
                            </Col>
                        </Row>
                    </Container>


                    <div className="copyright">
                        <Container>
                            <Row>
                                <Col xl="5" lg="6" md="8" sm="12" xs="12" className="d-flex justify-content-sm-start">
                                    <p>Copyright © Cerpromet d.o.o.  - 2019. All Rights Reserved.</p>
                                </Col>
                                <Col xl="3" lg="3" md="4" sm="12" xs="12" className="offset-xl-4 offset-lg-3 d-flex justify-content-xl-end justify-content-sm-center">
                                    <p>Created by <span><a href="https://aamedia.rs/" target="_blank">AA media</a></span></p>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </div>

            </>
        );
    }
}