import React from 'react';

import {
    Col,
    Row,
    Label,
    Input,
    Button,
    FormGroup,
    Container,


} from 'reactstrap';


export default class RightNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: {}
        }
    }

    render() {
        return (
            <>
                <div className="bg-white">
                    {
                        this.props.attributes.map((attr, idx) => {
                            return (
                                <div className={attr.Alias === 'zemlja-porekla' ? 'vine' : 'vine'}>
                                    <h2>{attr.Name} <button onClick={() => {
                                        let menu = this.state.menu;
                                        menu[attr._id] = !menu[attr._id];
                                        this.setState({ menu });
                                    }} className="collapseButton">{(!this.state.menu[attr._id] && idx < 2) || (this.state.menu[attr._id] && idx >= 2) ? "-" : "+"}</button></h2>
                                    {(!this.state.menu[attr._id] && idx < 2) || (this.state.menu[attr._id] && idx >= 2) ?
                                        <ul>
                                            {
                                                attr.Values.map((item, idx1) => {
                                                    /*if (attr.Alias === 'zemlja-porekla') {
                                                        return (
                                                            <li onClick={() => {
                                                                this.props.updateFilter(attr.Alias, item)
                                                            }}>{item}</li>

                                                        )
                                                    } else {*/
                                                    return (
                                                        <li><Input className={this.props.filters[attr.Alias] == item ? 'check': null} type="radio" name={attr.Alias} onChange={(e) => {
                                                            if (e.target.value)
                                                            this.props.updateFilter(attr.Alias, item)
                                                        }} />{item}</li>

                                                    )
                                                    //}
                                                })
                                            }
                                        </ul>
                                        : null}
                                </div>

                            )
                        })
                    }

                    {/*
                    <div className="vine">
                        <h2>BOJA VINA <button onClick={() => {
                            let menu = this.state.menu;
                            menu.item1 = !menu.item1;
                            this.setState({ menu });
                        }} className="collapseButton">{this.state.menu.item1 ? "+" : "-"}</button></h2>
                        {!this.state.menu.item1 ?
                            <ul>
                                <li><Input type="radio" name="vine" />Belo vino</li>
                                <li><Input type="radio" name="vine" />Crveno vino</li>
                                <li><Input type="radio" name="vine" />Oranž vino</li>
                                <li><Input type="radio" name="vine" />Penušavo vino</li>
                                <li><Input type="radio" name="vine" />Rose vino</li>
                            </ul>
                            : null}
                    </div>
                    <div className="country">
                        <h2>ZEMLJA <span></span> POREKLA <button onClick={() => {
                            let menu = this.state.menu;
                            menu.item2 = !menu.item2;
                            this.setState({ menu });
                        }} className="collapseButton">{this.state.menu.item2 ? "+" : "-"}</button></h2>
                        {!this.state.menu.item2 ?
                            <ul >
                                <li>Austrija (24)</li>
                                <li>BIH (12)</li>
                                <li>Crna Gora (8)</li>
                                <li>Francuska (234)</li>
                                <li>Grčka (23)</li>
                                <li>Hrvatska (54)</li>
                                <li>Italija  (534)</li>
                                <li>Srbija  (442)</li>
                            </ul>
                            : null}
                    </div>
                    <div className="vineType">
                        <h2>TIP VINA <button onClick={() => {
                            let menu = this.state.menu;
                            menu.item3 = !menu.item3;
                            this.setState({ menu });
                        }} className="collapseButton">{this.state.menu.item3 ? "-" : "+"}</button></h2>

                        {this.state.menu.item3 ?
                            <ul >
                                <li><Input type="radio" name="type" />Lorem ipsum</li>
                                <li><Input type="radio" name="type" />Lorem ipsum</li>
                                <li><Input type="radio" name="type" />Lorem ipsum</li>
                                <li><Input type="radio" name="type" />Lorem ipsum</li>
                                <li><Input type="radio" name="type" />Lorem ipsum</li>
                                <li><Input type="radio" name="type" />Lorem ipsum</li>

                            </ul>
                            : null}
                    </div>

                    <div className="sort">

                        <h2>SORTA  <button onClick={() => {
                            let menu = this.state.menu;
                            menu.item4 = !menu.item4;
                            this.setState({ menu });
                        }} className="collapseButton">{this.state.menu.item4 ? "-" : "+"}</button></h2>


                        {this.state.menu.item4 ?
                            <div className="scroll" >
                                <ul>
                                    <li className="no-pa"><Input type="radio" name="type" />Agiorgitiko (1)</li>
                                    <li><Input type="radio" name="type" />Aglianico (1)</li>
                                    <li><Input type="radio" name="type" />Albarino (2)</li>
                                    <li><Input type="radio" name="type" />Aleatico (2)</li>
                                    <li><Input type="radio" name="type" />Aleatico (2)</li>
                                    <li><Input type="radio" name="type" />Aleatico (2)</li>
                                </ul>
                            </div>
                            : null}
                    </div>

                        */}

                    <div className="price">

                        <h2>CENOVNI RANG <button onClick={() => {
                            let menu = this.state.menu;
                            menu.item5 = !menu.item5;
                            this.setState({ menu });
                        }} className="collapseButton">{this.state.menu.item5 ? "-" : "+"}</button></h2>


                        {this.state.menu.item5 ?
                            <div className="scroll" >
                                <ul>
                                    <li className="no-pa"><Input className={this.props.filters['priceMinMax'] == '1,2000' ? 'check': null} onChange={(e) => {
                                        if (e.target.value)
                                            this.props.updateFilter('priceMinMax', '1,2000')
                                    }} type="radio" name="type" />1-2.000 RSD</li>
                                    <li><Input className={this.props.filters['priceMinMax'] == '2001,4000' ?  'check': null} onChange={(e) => {
                                        if (e.target.value)
                                            this.props.updateFilter('priceMinMax', '2001,4000')
                                    }} type="radio" name="type" />2.001-4.000 RSD</li>


                                    <li><Input className={this.props.filters['priceMinMax'] == '4001,6000' ?  'check': null} onChange={(e) => {
                                        if (e.target.value)
                                            this.props.updateFilter('priceMinMax', '4001,6000')
                                    }} type="radio" name="type" />4.001-6.000 RSD</li>


                                    <li><Input className={this.props.filters['priceMinMax'] == '6001,8000' ?  'check': null} onChange={(e) => {
                                        if (e.target.value)
                                            this.props.updateFilter('priceMinMax', '6001,8000')
                                    }} type="radio" name="type" />6.001-8.000 RSD</li>


                                    <li><Input className={this.props.filters['priceMinMax'] == '8001,10000' ?  'check': null} onChange={(e) => {
                                        if (e.target.value)
                                            this.props.updateFilter('priceMinMax', '8001,10000')
                                    }} type="radio" name="type" />8.001-10.000 RSD</li>


                                    <li><Input className={this.props.filters['priceMinMax'] == '10001,12000' ?  'check': null} onChange={(e) => {
                                        if (e.target.value)
                                            this.props.updateFilter('priceMinMax', '10001,12000')
                                    }} type="radio" name="type" />10.001-12.000 RSD</li>
                                </ul>
                            </div>
                            : null}



                    </div>
                </div>

            </>
        );
    }
}